import { ErrorText } from 'components/basic/error'
import Box from 'components/styles/Box'
import Button from 'components/styles/Button'
import { Title } from 'components/styles/Card'
import Row from 'components/styles/Row'
import Text from 'components/styles/Text'
import useCall from 'components/tools/useCall'
import React from 'react'
import { FaPaperPlane } from 'react-icons/fa'

import Input from '../../styles/Input'

type Props = {
  email: string
  setEmail: (email: string) => void
  back: () => void
}

const ResetPassword: React.FC<Props> = ({ email, setEmail, back }) => {
  const { call, inProgress, error, success } = useCall('login/reset')

  const handleRequestResetPassword = () => {
    call({ email })
  }

  const renderReset = () => {
    return (
      <Box maxWidth="smallModal" m="normal" mt={0}>
        <Title>Nulstil kodeord</Title>
        <Text my={2}>
          Indtast din e-mail-adresse nedenfor, hvis e-mail-adressen findes i
          systemet vil du få tilsendt en e-mail der giver dig mulighed for at
          oprette et nyt kodeord.
        </Text>
        <Input
          mb="3"
          type="email"
          label="Email"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
        <ErrorText>{error?.message}</ErrorText>
        <Row spaceBetween>
          <Button onClick={back} text>
            Tilbage
          </Button>
          <Button
            onClick={handleRequestResetPassword}
            round
            loading={inProgress}
          >
            Nulstil kodeord
          </Button>
        </Row>
      </Box>
    )
  }

  const renderSuccess = () => {
    return (
      <Box
        maxWidth="smallModal"
        m="0"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box my="2">
          <FaPaperPlane size={170} color="#0b3656" />
        </Box>

        <Text fontSize="6" my="2">
          E-mail afsendt
        </Text>

        <Text fontSize="4" mx="2" textAlign="center" mb="4">
          For at nulstille din adgangskode skal du blot følge linket i e-mailen.
        </Text>
      </Box>
    )
  }

  return success ? renderSuccess() : renderReset()
}

export default ResetPassword
