import React from 'react'
import { Box } from '@xstyled/styled-components'
import Text from 'components/styles/Text'
import Tsukuri from './Tsukuri'
import Kuzushi from './Kuzushi'
// import kake from './kake1.svg'
// import ReactLogo from './kake1.svg'
import Kake from './Kake'

// const Tsukuri = styled.img`
//   margin-top: 50px;
//   width: 160px;
//   height: 200px;

//   /* color: #fff; */
// `
// const Kuzushi = styled.img`
//   margin-top: 50px;
//   width: 180px;
//   height: 200px;
//   /* color: #fff; */
// `
// const Kake = styled.img`
//   margin-top: 50px;
//   width: 200px;
//   height: 200px;
//   /* color: #fff; */
//   fill: blue;
//   svg: {
//     fill: green;
//   }
// `
// const Color = 'blue'
const Color = '#c1432a'
const FrontPageV3 = () => {
  return (
    <Box p="2">
      <Box display="flex">
        <Box width="60vw">
          <Text fontSize="2.8em" mt="2">
            Velkommen
          </Text>
          <Text fontSize="1.1rem" ml="2">
            til Århus Judo Klub
          </Text>
          <Text mt="3">Vi har hold på flere niveauer, så om du er erfaren eller helt ny</Text>
          <Text>- så har vi et godt hold, der passer til dig!</Text>
        </Box>
        <Tsukuri fill={Color} width="400" />
      </Box>

      <Box mt="3">
        <Text fontSize="1.2rem">Udover judo - i alle aldersgruppe, har vi også aikido og jiu jitsu</Text>
      </Box>

      <Box display="flex">
        <Kuzushi fill={Color} width="500" />

        <Box ml="2">
          <Text fontSize="2.3rem" mt="3">
            Kig forbi
          </Text>
          <Text mt="2">Du er altid velkommen til at komme forbi og prøve en træning</Text>
          <Text mt="1">Du behøver ikke at ringe først, du kan bare møde op til træningen, så hjælper vi dig godt i gang.</Text>
        </Box>
      </Box>
      <Text mt="2" mb="3" fontSize="1.3rem" textAlign="center">
        De første 4 træninger er gratis!
      </Text>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Kake fill={Color} />
      </Box>

      <div>
        <Text fontSize="2rem" mt="4">
          Træningstider og hold
        </Text>
      </div>
    </Box>
  )
}

export default FrontPageV3
