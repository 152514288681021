import React, { FC } from 'react'

import usePlans from '../usePlans'
import useView from './viewState'

import OverviewCard from './OverviewCard'
import { CardGrid } from '../../styles/Card'
import SubscribeModalV2 from '../SubscribeModalV2'

import ErrorText from 'components/basic/error'
import { DelayedLoading } from 'components/loading'
import { navigate } from 'hookrouter'

const EventOverview: FC = () => {
  const { plans, fetching, error } = usePlans()
  const viewProps = useView((s) => ({
    setShow: s.setShowModal,
    show: s.showModal,
    plan: s.plan,
    planId: s.plan.id,
  }))

  if (error) return <ErrorText>{error}</ErrorText>
  if (fetching) return <DelayedLoading high />

  const onClose = () => {
    viewProps.setShow(false)
    navigate(`/teams/${viewProps.plan.slug}`)
  }

  return (
    <>
      <CardGrid>
        {plans?.map((plan) => (
          <OverviewCard plan={plan} key={plan.id} />
        ))}
      </CardGrid>
      {viewProps.show && <SubscribeModalV2 onClose={onClose} {...viewProps} />}
    </>
  )
}

export default EventOverview
