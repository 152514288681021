import styled, { Box } from '@xstyled/styled-components'
import ErrorText from 'components/basic/error'
import { LoadingHigh } from 'components/loading'
import Button from 'components/styles/Button'
import { A } from 'hookrouter'
import React, { useState } from 'react'
import { MdEdit } from 'react-icons/md'
import useSWR from 'swr'

import RoleAuth from '../basic/roleAuth.jsx'
import roles from '../basic/roles'
import EditLinkModal from './EditLinkModal'

const Columns = styled.div`
  display: grid;
  grid-gap: 4;
  margin-top: 4;
  grid-template-columns: 1fr 1fr 1fr;

  @media only screen and (max-width: 52em) {
    grid-gap: 0;
    margin-top: 0;
    grid-template-columns: 1fr;
  }
`
const Column = styled.div``

const Item = styled(A)`
  background-color: white;
  padding: 2;
  display: flex;
  border-bottom: 1px solid;
  border-color: border;
  justify-content: space-between;
  align-items: center;
  color: black;
  cursor: pointer;
`

type Response = {
  links: Column[]
}

type Column = Item[]

type Item = {
  title: string
  slug: string
}

type State = {
  columnIdx: number
  itemIdx: number | undefined
  title: string
  slug: string
}

const InfoPage = () => {
  const { data, error } = useSWR<Response>('info')
  const [payload, setPayload] = useState<State | null>(null)

  const handleEdit = (
    event: any,
    columnIdx: number,
    itemIdx: number,
    title: string,
    slug: string,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    setPayload({ columnIdx, itemIdx, title, slug })
  }
  const handleAdd = (columnIdx) => {
    const itemIdx = data?.links[columnIdx].length
    setPayload({ columnIdx, itemIdx, title: '', slug: '' })
  }

  const renderColumn = (column, columnIdx) => {
    return (
      <Column key={columnIdx}>
        {column.map((c, itemIdx) => renderItem(columnIdx, itemIdx, c))}
        <RoleAuth allowedRole={roles.editor}>
          <Box my="2">
            <Button center onClick={() => handleAdd(columnIdx)}>
              Tilføj link
            </Button>
          </Box>
        </RoleAuth>
      </Column>
    )
  }

  const renderItem = (columnIdx: number, itemIdx: number, item: Item) => {
    const { title, slug } = item
    return (
      <Item href={'/info/' + slug} key={itemIdx}>
        {title}
        <RoleAuth allowedRole={roles.editor}>
          <MdEdit
            onClick={(e) => handleEdit(e, columnIdx, itemIdx, title, slug)}
          />
        </RoleAuth>
      </Item>
    )
  }
  if (error) return <ErrorText>{error.message}</ErrorText>
  if (!data) return <LoadingHigh />

  return (
    <>
      <Columns>{data.links.map(renderColumn)}</Columns>
      {payload && (
        <EditLinkModal {...payload} onClose={() => setPayload(null)} />
      )}
    </>
  )
}

export default InfoPage
