import React, { FC } from 'react'
import { A } from 'hookrouter'
import styled from 'styled-components'

import LoginModal from '../userManagement/login'
import MenuDropdown from '../basic/menuDropdown'
import logout from '../userManagement/logout'
import useUser from 'components/userManagement/useUser'
import useAuthModals from 'components/userManagement/Register/useAuthModals'
import Register from 'components/userManagement/Register'

const Items = styled.div``

const Item = styled.span`
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 20px;
  text-transform: capitalize;

  @media only screen and (min-width: 768px) {
    font-size: 1.3rem;
  }
`
const Cta = styled.span`
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 20px;
  text-transform: capitalize;
  border: solid 1px white;
  padding: 10px;
  border-radius: 8px;

  @media only screen and (min-width: 768px) {
    font-size: 1.2rem;
    :hover {
      color: white;
      background-color: #324356;
    }
  }
`

const Profile: FC = () => {
  const { user } = useUser()
  const { setShowLogin, setShowRegister } = useAuthModals((p) => ({
    setShowLogin: p.setShowLogin,
    setShowRegister: p.setShowRegister,
  }))

  return (
    <>
      {user.authenticated ? (
        <MenuDropdown headline={<Item>Profil</Item>} numberOfItems={3}>
          <A href="/profile">Ret profil</A>
          <A href="/profile/subscriptions">Tilmeldte hold</A>
          <div onClick={logout}>Log ud</div>
        </MenuDropdown>
      ) : (
        <Items>
          <Item onClick={() => setShowLogin(true)}>Login</Item>
          <Cta onClick={() => setShowRegister(true)}>Tilmeld</Cta>
        </Items>
      )}
      <LoginModal />
      <Register />
    </>
  )
}

export default Profile
