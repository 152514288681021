import Axios, {} from 'axios'

const post = <T = any, S = any>(url: string, payload: S) =>
  Axios.post<T>(`/api/${url}`, payload)
const put = <T = any, S = any>(url: string, payload: S) =>
  Axios.put<T>(`/api/${url}`, payload)
const get = <T = any>(url: string) => Axios.get<T>(`/api/${url}`)
const del = <T = any>(url: string) => Axios.delete<T>(`/api/${url}`)

export default { post, put, get, del }
