import React, { FC } from 'react'
import PersonalInfo from './PersonalInfo'

import { LoadingHigh } from '../loading'
import ErrorText from '../basic/error'
import useSWR from 'swr'

const Profile: FC = () => {
  const { data, error } = useSWR('profile')

  if (error) return <ErrorText>{error.message}</ErrorText>
  if (!data) return <LoadingHigh />
  return (
    <div>
      <PersonalInfo />
    </div>
  )
}

export default Profile
