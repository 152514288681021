import { Box } from '@xstyled/styled-components'
import React from 'react'
import { FaLocationArrow } from 'react-icons/fa'
import styled from 'styled-components'
import { siteWidth } from 'styles'

import { FullCard, Subtitle, Title } from '../../basic/card'
import { longDates } from '../../basic/date'
import CalendarIcon from './calendarCard'

const LocationRow = styled.div`
  color: #696969;
`

const Icon = styled.i`
  margin: 0em 0.7rem 0em 0em;
  width: 1.18em;
`

const HorizontalFullCard = styled(FullCard)`
  display: flex;
  width: 100%;
  margin: 2em 0;
  @media only screen and (max-width: ${siteWidth}px) {
    width: auto;
    margin: 16px;
  }
`

const Calendar = ({ calendar }) => {
  const calendarItem = (item) => {
    const { iCalUID, htmlLink, startTime, endTime, title, location, description } = item
    return (
      <HorizontalFullCard key={iCalUID}>
        <CalendarIcon link={htmlLink}>{startTime}</CalendarIcon>
        <Box m="3" mt="2">
          <Subtitle> {longDates(startTime, endTime)}</Subtitle>
          <Title>{title}</Title>
          {location && (
            <LocationRow>
              <Icon>
                <FaLocationArrow />
              </Icon>
              {location}
            </LocationRow>
          )}
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Box>
      </HorizontalFullCard>
    )
  }

  if (calendar && calendar.items && calendar.items.length) {
    return <div>{calendar.items.map((item) => calendarItem(item))}</div>
  } else {
    return null
  }
}

export default Calendar
