import styled, { Box } from '@xstyled/styled-components'
import Text from 'components/styles/Text.jsx'
import { A } from 'hookrouter'
import React, { useState } from 'react'
import { MdAdd, MdEdit } from 'react-icons/md'
import useSWR from 'swr'

import RoleAuth from '../basic/roleAuth.jsx'
import roles from '../basic/roles'
import EditLinkModal from './EditLinkModal'
import logo from './logo-hvid.svg'

const Container = styled.div`
  display: none;
  margin-top: 35px;
  @media only screen and (min-width: 768px) {
    width: 100%;
    background-color: #061723;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const Columns = styled.div`
  display: flex;
  justify-content: space-evenly;
`

const Column = styled.div`
  margin: 4 0;
  width: 300px;
  color: white;
  font-weight: 500;
  font-size: 1.3em;
  line-height: 2em;
  padding-left: 20px;
  border-left: 5px solid #01213a;
`

const Item = styled(A)`
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
`

const Logo = styled.img`
  margin-top: 50px;
  width: 200px;
  height: 200px;
  color: #fff;
`

type Response = {
  links: Column[]
}

type Column = Item[]

type Item = {
  title: string
  slug: string
}

type State = {
  columnIdx: number
  itemIdx: number | undefined
  title: string
  slug: string
}
const Footer = () => {
  const { data } = useSWR<Response>('info')

  const [payload, setPayload] = useState<State | null>(null)

  const handleEdit = (
    event: any,
    columnIdx: number,
    itemIdx: number,
    title: string,
    slug: string,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    setPayload({ columnIdx, itemIdx, title, slug })
  }

  const handleAdd = (columnIdx: number) => {
    const itemIdx = data?.links[columnIdx].length
    setPayload({ columnIdx, itemIdx, title: '', slug: '' })
  }

  const renderColumn = (column: Column, columnIdx: number) => {
    return (
      <Column key={columnIdx}>
        {column.map((c, itemIdx) => renderItem(columnIdx, itemIdx, c))}
        <RoleAuth allowedRole={roles.editor}>
          <Box my="2">
            <Item href="#" onClick={() => handleAdd(columnIdx)}>
              <MdAdd />
              <Text ml="2">Tilføj link</Text>
            </Item>
          </Box>
        </RoleAuth>
      </Column>
    )
  }

  const renderItem = (columnIdx: number, itemIdx: number, item: Item) => {
    const { title, slug } = item
    return (
      <Item href={'/info/' + slug} key={itemIdx}>
        <RoleAuth allowedRole={roles.editor}>
          <MdEdit
            onClick={(e) => handleEdit(e, columnIdx, itemIdx, title, slug)}
          />
        </RoleAuth>
        <Text ml="2"> {title}</Text>
      </Item>
    )
  }

  return (
    <>
      <Container>
        <Columns>{data?.links.map(renderColumn)}</Columns>
        <div>
          <Logo src={logo} alt="" />
        </div>
        <Text color="white" my="4">
          Århus Judo Klub - Finlandsgade 25, 8200 Århus N - CVR: 43319310
        </Text>
      </Container>
      {payload && (
        <EditLinkModal {...payload} onClose={() => setPayload(null)} />
      )}
    </>
  )
}

export default Footer
