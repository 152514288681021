export const color = '#2185d0'

export const colors = {
  primary: '#2185d0',
  primaryDark: '#1c76b9',
  footer: '#061723',
  border: '#dadce0',
  hover: '#d7e9f7',
  danger: '#da0000',
  dangerDark: '#af0000',
  warning: '#ff9800',
  mutedText: '#585858',
  disabled: '#9bccf1',
  link: '#2185d0',
  green:"#26b741"
}

export const siteWidth = '767px'
export const borderRadius = '8px'
export const padding = '16px'
