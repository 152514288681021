import React, { useState, useEffect } from 'react'
import request from 'components/tools/request'
import styled, { Box } from '@xstyled/styled-components'
import { LoadingHigh } from 'components/loading'
import Text from 'components/styles/Text'
import Button from 'components/styles/Button'
import Modal from 'components/basic/Modal/Modal'
import Input from 'components/styles/Input'

import Attendee from './Attendee'
import { A } from 'hookrouter'
import ErrorText from 'components/basic/error'
import useSWR from 'swr'

const Table = styled.table`
  margin: 2em 0;
  font-size: 1.2em;
  border-collapse: collapse;
  width: 100%;
`
const Th = styled.th`
  font-weight: 500;
  border-bottom: 1px solid #e1e1e1;
  padding: 0 10px;
  text-align: left;
`
const Td = styled.td`
  font-weight: 600;
  border-top: 2px solid #e1e1e1;
  padding: 10px 20px;
  text-align: left;
`

const Dato = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 2em;

  position: relative;

  input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    padding: 0;
  }
  input::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  input:hover + button {
    background-color: silver;
  }
`

const AttendeeList = ({ slug }) => {
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10))
  const [dateString, setDateString] = useState(date.replace(/-/g, ''))
  const [name, setName] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState(null)
  const [total, setTotal] = useState(0)

  const { data: users, mutate } = useSWR(`admin/attendees/${slug}/${dateString}`)

  const increase = () => {
    setTotal((val) => val + 1)
  }
  const decrease = () => {
    setTotal((val) => val - 1)
  }

  useEffect(() => {
    if (users) {
      setTotal(users.reduce((total, current) => (current.date ? total + 1 : total), 0))
    }
  }, [users])

  const changeDate = (e) => {
    const val = e.target.value
    if (val) {
      setDate(val)
      setDateString(val.replace(/-/g, ''))
    }
  }

  const createPerson = async () => {
    try {
      setInProgress(true)
      const resp = await request.post('admin/attendee', { name, slug })
      setName('')
      setIsOpen(false)
      mutate((d) => [...d, resp.data], false)
    } catch (error) {
      console.log(error)
      setError(error)
    } finally {
      setInProgress(false)
    }
  }

  if (!users) return <LoadingHigh />

  return (
    <div>
      <A href={`${slug}/attendance`}>
        <Button text center>
          Oversigt
        </Button>
      </A>
      <Dato>
        <input type="date" onChange={changeDate} value={date} />
        <Text fontSize="4">{new Date(date).toLocaleDateString()}</Text>
        <Text color="link">Ændre</Text>
      </Dato>
      <Table>
        <thead>
          <tr>
            <th />
            <Th>Navn</Th>
            <Th></Th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <Attendee {...user} key={user.id} slug={slug} dateString={dateString} increase={increase} decrease={decrease} />
          ))}

          <tr>
            <td></td>
            <Td>I alt</Td>
            <Td>{total}</Td>
          </tr>
        </tbody>
      </Table>
      <Button center text onClick={() => setIsOpen()}>
        Tilføj person
      </Button>

      <Modal isOpen={isOpen} dialog toggle={() => setIsOpen(false)} persistent={inProgress}>
        <Box p="2" pt="0">
          <Text fontSize="3" mb="2">
            Tilføj person
          </Text>
          <Input mb="2" label="Navn" value={name} onChange={(e) => setName(e.target.value)} />
          <Button round right loading={inProgress} onClick={createPerson}>
            Tilføj
          </Button>
          {error && <ErrorText>{error.toString()}</ErrorText>}
        </Box>
      </Modal>
    </div>
  )
}

export default AttendeeList
