import ErrorText from 'components/basic/error'
import Modal from 'components/basic/Modal'
import Box from 'components/styles/Box'
import Button from 'components/styles/Button'
import Input from 'components/styles/Input'
import List from 'components/styles/List'
import ListItem from 'components/styles/ListItem'
import Text from 'components/styles/Text'
import nameFilter from 'components/tools/nameFilter'
import request from 'components/tools/request'
import React, { useState } from 'react'
import useSWR, { mutate } from 'swr'

type Props = {
  onClose: () => void
  planId: string
  planName: string
  nextPaymentDate: Date
}

type Person = {
  id: number
  firstName: string
  lastName: string
  birth: Date
}

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

const AdminSignUpModal: React.FC<Props> = ({
  onClose,
  planName,
  planId,
  nextPaymentDate,
}) => {
  const { data } = useSWR<Person[]>('persons/all')

  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null)
  const [term, setTerm] = useState<string>('')

  const [inProgress, setInProgress] = useState<boolean>(false)
  const [error, setError] = useState<null | Error>(null)

  const persons = (data || []).filter((p) =>
    nameFilter(term, p.firstName, p.lastName),
  )
  const handleSignUp = async () => {
    try {
      setInProgress(true)
      setError(null)
      await request.post('subscription-v2/admin-sign-up', {
        planId,
        personId: selectedPerson?.id,
      })

      await mutate('subscription-v2/all')
      await mutate('subscription-v2/persons')
      onClose()
    } catch (error) {
      setInProgress(false)
      setError(error)
    }
  }

  const renderPerson = (p: Person) => {
    const { firstName, lastName, id, birth } = p
    return (
      <ListItem key={id} as="div" onClick={() => setSelectedPerson(p)}>
        <Text>{`${firstName} ${lastName}`}</Text>
        <Text>{new Date(birth).toLocaleDateString()}</Text>
      </ListItem>
    )
  }

  const renderPersons = () => {
    return (
      <Box>
        <Input
          borderRadius="0"
          mt="2"
          label="Søg"
          value={term}
          onChange={(e) => setTerm(e.currentTarget.value)}
        />
        <List>{persons.map(renderPerson)}</List>
      </Box>
    )
  }

  const renderConfirm = () => {
    const { firstName, lastName } = selectedPerson || {}
    return (
      <Box m="2">
        <Text
          mb="1"
          fontSize="4"
        >{`Tilmeld ${firstName} ${lastName} til ${planName}`}</Text>
        <Text
          mb="3"
          fontSize="3"
        >{`Tilmeldingen vi være gyldig indtil ${nextPaymentDate.toLocaleDateString('da', dateOptions)}`}</Text>

        <Box display="flex" justifyContent="flex-end">
          <Button
            disabled={inProgress}
            text
            onClick={() => setSelectedPerson(null)}
          >
            Tilbage
          </Button>
          <Button onClick={handleSignUp} loading={inProgress}>
            Tilmeld
          </Button>
        </Box>
        {error && <ErrorText>{error.message}</ErrorText>}
      </Box>
    )
  }

  return (
    <Modal isOpen toggle={onClose} persistent={inProgress}>
      <Box>{selectedPerson ? renderConfirm() : renderPersons()}</Box>
    </Modal>
  )
}

export default AdminSignUpModal
