import styled, { Box } from '@xstyled/styled-components'
import Button from 'components/styles/Button'
import Card from 'components/styles/Card'
import { Title } from 'components/styles/Card'
import useAuthModals from 'components/userManagement/Register/useAuthModals'
import useUser from 'components/userManagement/useUser'
import { A } from 'hookrouter'
import React, { useState } from 'react'
import { FaCopy, FaEdit } from 'react-icons/fa'
import roles from 'roles'

import CreateUpdateEvent from '../CreateUpdate'
import EventInfo from '../EventInfo'
import { isExpired } from '../isExpired'
import SignUpModal from '../SignUpModal'

const Content = styled.div`
  flex-grow: 1;
  margin: 2 0;
`

const OverviewCard = ({ event }) => {
  const { user } = useUser()
  const setShowLogin = useAuthModals((p) => p.setShowLogin)

  const [isOpen, setIsOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isCopying, setIsCopying] = useState(false)

  const {
    name,
    slug,
    startDateTime,
    endDateTime,
    price,
    registrationEnd,
    location,
  } = event
  const handleSignUp = () => {
    if (user.authenticated) {
      setIsOpen(true)
    } else {
      setShowLogin(true)
    }
  }

  return (
    <>
      <Card mt>
        {user.roles.includes(roles.eventManger) && (
          <Box display="flex" justifyContent="flex-end">
            <Box p="1">
              <FaCopy onClick={() => setIsCopying(true)} />
            </Box>
            <Box p="1">
              <FaEdit onClick={() => setIsEditing(true)} />
            </Box>
          </Box>
        )}
        <Box display="flex" flexDirection="column" height="100%" p="2">
          <Title>{name}</Title>

          <Content>
            <EventInfo
              startDateTime={startDateTime}
              endDateTime={endDateTime}
              price={price}
              registrationEnd={registrationEnd}
              location={location}
            />
          </Content>

          <Box display="flex" justifyContent="flex-end">
            <A href={`/events/${slug}`}>
              <Button outline>Læs mere</Button>
            </A>
            <Button
              ml
              onClick={handleSignUp}
              disabled={isExpired(registrationEnd)}
            >
              Tilmeld
            </Button>
          </Box>
        </Box>
      </Card>
      {isOpen && <SignUpModal slug={slug} onClose={() => setIsOpen(false)} />}
      {isEditing && (
        <CreateUpdateEvent
          isEditing
          slug={slug}
          onClose={() => setIsEditing(false)}
        />
      )}
      {isCopying && (
        <CreateUpdateEvent
          slug={slug}
          onClose={() => setIsCopying(false)}
          isEditing={false}
        />
      )}
    </>
  )
}

export default OverviewCard
