import React from 'react'
import styled from 'styled-components'

import Card, { Scroll, Title } from '../../../styles/Card'

const Table = styled.table`
  margin: 2em 0;
  font-size: 1.2em;
  border-collapse: collapse;
  width: 100%;
`
const Th = styled.th`
  font-weight: 500;
  border-bottom: 1px solid #e1e1e1;
  padding-right: 20px;
  text-align: left;
`

const Tr = styled.tr`
  height: 2.5em;
  :hover {
    background-color: #d7e9f7;
  }
  cursor: pointer;
`

const Td = styled.td`
  white-space: nowrap;
  padding-right: 20px;
`

const options = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
  hour: 'numeric',
  minute: '2-digit'
}

const List = ({ data, timestamp, onClick }) => {

  const renderRow = (customer, index) => {
      const {email, fullName,failure_message, created } = customer
    return (
      <Tr key={index} onClick={()=>onClick(customer)}>
        <Td>{new Date(created).toLocaleDateString('da-DK')}</Td>
        <Td>{email}</Td>
        <Td>{fullName}</Td>
        <Td>{failure_message}</Td>
      </Tr>
    )
  }

  return (
    <Scroll>
      <Card padding mt fullWidth>
        <Title>Fejlne betalinger</Title>
        <i>De 100 seneste fejlne betalinger, opdateret {timestamp.toLocaleString('da-DK', options)}</i>
        <Table>
          <thead>
            <tr>
              <Th>Dato</Th>
              <Th>Email</Th>
              <Th>Navn</Th>
              <Th>Fejlbesked</Th>
            </tr>
          </thead>
          <tbody>{data.map((e, index) => renderRow(e, index))}</tbody>
        </Table>
      </Card>
    </Scroll>
  )
}

export default List
