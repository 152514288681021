import React from 'react'
import { navigate } from 'hookrouter'

const LinkCapture = ({ children }) => {
  const captureClicks = event => {
    if (event.target.localName === 'a') {
      event.preventDefault()

      const address = event.target.href
      const host = window.location.hostname

      if (address.includes(host)) {
        navigate(address)
      } else {
        window.open(address, '_blank')
      }
    }
  }

  return <div onClick={captureClicks}>{children}</div>
}

export default LinkCapture
