import React, { FC, useCallback, useMemo, useState } from 'react'
import Button from '../../styles/Button'
import useSWR from 'swr'
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import request from 'components/tools/request'
import STRIPE_KEY from 'keys'
import Text from 'components/styles/Text'

type Props = {
  planId: number
  personId: number
  onClose: (oneMore?: boolean) => void
  showOneMore?: boolean
}

const SubscribePayment: FC<Props> = ({
  planId,
  onClose = () => {},
  personId,
  showOneMore,
}) => {
  const [error, setError] = useState<string>('')
  const [sessionId, setSessionId] = useState<string>('')
  const [showSuccess, setShowSuccess] = useState(false)
  const { data } = useSWR(
    sessionId ? `subscription-v2/session-status?session_id=${sessionId}` : null,
    {
      refreshInterval(latestData?: { status: 'open' | 'complete' }) {
        if (latestData?.status === 'complete') return 0
        return 3000
      },
    },
  )

  const stripePromise = useMemo(() => loadStripe(STRIPE_KEY), [])

  const fetchClientSecret = useCallback(async () => {
    // Create a Checkout Session
    try {
      const result = await request.post(
        'subscription-v2/create-checkout-session',
        {
          planId: planId,
          personId: Number(personId),
        },
      )
      setSessionId(result.data.sessionId)
      return result.data.clientSecret
    } catch (error) {
      const errorMsg =
        error?.response?.data?.message || error?.message || 'Noget gik galt'
      setError(errorMsg)
    }
  }, [planId, personId])

  const options = {
    fetchClientSecret,
    onComplete: () => {
      setShowSuccess(true)
    },
  }

  return (
    <div id="checkout">
      {error ? (
        <Text fontSize="1.2rem" mt="6" textAlign="center">
          {error}
        </Text>
      ) : (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
      {showSuccess && (
        <>
          <Button
            mt="2"
            fullWidth
            loading={data === null || data?.status === 'open'}
            onClick={onClose}
          >
            Wuhuu - forsæt
          </Button>
          {showOneMore && (
            <Button
              text
              mt="2"
              fullWidth
              loading={data === null || data?.status === 'open'}
              onClick={() => onClose(true)}
            >
              Tilmeld en til
            </Button>
          )}
        </>
      )}
    </div>
  )
}

export default SubscribePayment
