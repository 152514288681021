import React from 'react'
import Box from './Box'

type Props = {}

const List: React.FC<Props> = ({ children, ...restProps }) => {
  return (
    <Box display="flex" flexDirection="column" {...restProps}>
      {children}
    </Box>
  )
}

export default List
