import ErrorText from 'components/basic/error'
import Box from 'components/styles/Box'
import Button from 'components/styles/Button'
import Input from 'components/styles/Input'
import Text from 'components/styles/Text'
import request from 'components/tools/request'
import { A } from 'hookrouter'
import React, { useState } from 'react'
import { MdLockOpen } from 'react-icons/md'

import useUser from './useUser'

type Props = { token: string }

type Response = {
  token: string
  tokenExpires: string
  roles: string[]
  id: number
}

const ResetPassword: React.FC<Props> = ({ token }) => {
  const [password, setPassword] = useState<string>('')
  const { setUser } = useUser()

  const [inProgress, setInProgress] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const handleSave = async () => {
    try {
      setError(null)
      setInProgress(true)

      if (password.length < 8) throw Error('Kodeord skal minimum være 8 tegn.')

      const { data: user } = await request.post<Response>('login/newPassword', { token, password })
      setUser({ ...user, authenticated: true })
      setInProgress(false)
      setSuccess(true)
    } catch (error) {
      setInProgress(false)
      setError(error)
    }
  }

  if (success)
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" mt="4">
        <MdLockOpen size={170} color="#0b3656" />

        <Text fontSize="1.3rem" my="2">
          Adgangskode ændret
        </Text>
        <A href="/">Til forsiden</A>
      </Box>
    )

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" mt="4">
      <Text fontSize="1.3rem" my="2">
        Ny adgangskode
      </Text>

      <Input type="password" onChange={(e) => setPassword(e.currentTarget.value)} value={password} disabled={inProgress} />
      {error && <ErrorText>{error.message}</ErrorText>}

      <Button mt onClick={handleSave} loading={inProgress}>
        Gem
      </Button>
    </Box>
  )
}

export default ResetPassword
