import React from 'react'
import Subscriptions from 'components/plans/MySubscriptions'
import Text from 'components/styles/Text'
// import PaymentCard from 'components/plans/PaymentCard'
import useUser from 'components/userManagement/useUser'
import useAuthModals from 'components/userManagement/Register/useAuthModals'

const MySubscriptions: React.FC = () => {
  const { user } = useUser()
  const { setShowLogin, showLogin } = useAuthModals((p) => ({
    setShowLogin: p.setShowLogin,
    showLogin: p.showLogin,
  }))

  if (!user.authenticated && !showLogin) {
    setShowLogin(true)
  }

  if (!user.authenticated) return null

  return (
    <>
      {/* <PaymentCard /> */}
      <Text m="2" fontSize="5">
        Holdtilmeldinger
      </Text>
      <Subscriptions />
    </>
  )
}

export default MySubscriptions
