import React, { useState, Suspense } from 'react'
import useSWR from 'swr'
import { Box } from '@xstyled/styled-components'
import Button from 'components/styles/Button'
import Card from 'components/styles/Card'
import Text from 'components/styles/Text'
import Loading from 'components/loading'
import request from 'components/tools/request'
import { URL_KEY } from '.'

const Editor = React.lazy(() => import('../../basic/EditorV2'))

const EditInfoBox = ({ onClose }) => {
  const { data, mutate } = useSWR(URL_KEY)
  const [text, setText] = useState(data.info.body)
  const [inProgress, setInProgress] = useState(false)

  const handleSave = async () => {
    try {
      setInProgress(true)
      const resp = await request.put('infoCard/event', { body: text })

      await mutate((data) => ({
        ...data,
        info: { ...data.info, ...resp.data },
      }))
      setInProgress(false)
      onClose()
    } catch (_) {
      setInProgress(false)
    }
  }

  return (
    <Suspense fallback={<Loading />}>
      <Card mt>
        <Editor initialValue={text} onChange={(html) => setText(html)} />
      </Card>
      <Box display="flex" justifyContent="space-between" mt="2">
        <Text ml="2">Slet alt tekst for at fjerne boksen</Text>
        <Box display="flex">
          <Button text onClick={onClose} disabled={inProgress}>
            Fortryd
          </Button>
          <Button loading={inProgress} onClick={handleSave}>
            Gem
          </Button>
        </Box>
      </Box>
    </Suspense>
  )
}

export default EditInfoBox
