import Modal from 'components/basic/Modal'
import { DelayedLoading } from 'components/loading'
import Box from 'components/styles/Box'
import useAuthModals from 'components/userManagement/Register/useAuthModals'
import React, { Suspense, useState } from 'react'
import ChoosePlan from './ChoosePlan'
import CreateUser from './CreateUser'

export type ViewState = 'create' | 'plan' | 'person' | 'confirm'

const Register: React.FC = () => {
  const [view, setView] = useState<ViewState>('create')
  const [inProgress, setInProgress] = useState<boolean>(false)

  const { setShow, show } = useAuthModals((p) => ({
    show: p.showRegister,
    setShow: p.setShowRegister,
  }))

  const handleClose = () => setShow(false)

  if (!show) return null

  return (
    <Modal isOpen={true} toggle={handleClose} persistent={inProgress}>
      <Box minHeight="250px">
        <Suspense fallback={<DelayedLoading high />}>
          {view === 'create' && (
            <CreateUser
              // setView={handleClose}
              setView={setView}
              setInProgress={setInProgress}
              inProgress={inProgress}
            />
          )}
          {view === 'plan' && (
            <ChoosePlan
              // setView={setView}
              onClose={handleClose}
            />
          )}
        </Suspense>
      </Box>
    </Modal>
  )
}

export default Register

//TODO:
//Handle date/birth input on safari macOS
