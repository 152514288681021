const roles = {
    "super-admin": "super-admin",
    "admin": "admin",
    "event-manager": "event-manager",
    "cashier": "cashier",
    "team-manager": "team-manager",
    "news-editor": "news-editor",
    "editor": "editor",
    "coach": "coach"
}

/**
 *
 * @param {string} role the allowed role
 * @param {string[]} userRoles the users roles
 */
export function isInRole(role, userRoles) {
    if (!role || !userRoles.length) {
        return false
    } else{
       return userRoles.includes(role)
    }
}

export default roles