const roles = {
  eventManger: 'event-manager',
  newsEditor: 'news-editor',
  editor: 'editor',
  shopManager: 'shop-manager',
  coach: 'coach',
  roleManager: 'role-manager',
  cashier: 'cashier',
  teamManager: 'team-manager',
  admin: 'admin',
  attendeeManager: 'attendee-manager',
}

export default roles
