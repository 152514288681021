import create from 'zustand'

const [useView] = create((set) => ({
  plan: {},
  user: {},
  showModal: false,

  setPlan: plan => set(() => ({ plan })),
  setUser: user => set(() => ({ user })),
  setShowModal: val => set(() => ({ showModal: val }))
}))

export default useView
