import styled, { Box } from '@xstyled/styled-components'
import React from 'react'
import { FaExternalLinkAlt, FaInfo, FaMap, FaMoneyBillWave } from 'react-icons/fa'
import { MdCalendarToday } from 'react-icons/md'

import formatDateRange from './formatDateRange'

const dateFormatter = new Intl.DateTimeFormat('da', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
})

const Row = styled.div`
  margin: 1 0;
  color: mutedText;
`
const Icon = styled.i`
  width: 10px;
  margin-right: 1;
`

type Props = { startDateTime: string; endDateTime: string; price: string; registrationEnd: string; location: string }

const EventInfo: React.FC<Props> = ({ startDateTime, endDateTime, price, registrationEnd, location }) => {
  const mapsLink = () => {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`
  }

  const renderRow = (icon, text) => {
    return (
      <Row>
        <Icon>{icon}</Icon>
        {text}
      </Row>
    )
  }
  const renderLocation = () => {
    if (!location) return null
    const showMaps = location && location.length > 10

    return (
      <Row>
        <Icon>
          <FaMap />
        </Icon>
        {location}
        {showMaps && (
          <Box display="inline" ml="1">
            <a href={mapsLink()} target="_blank" rel="noopener noreferrer">
              <FaExternalLinkAlt />
            </a>
          </Box>
        )}
      </Row>
    )
  }

  const start = new Date(startDateTime)
  const end = new Date(endDateTime)
  return (
    <div>
      {start < end && renderRow(<MdCalendarToday />, formatDateRange(start, end))}
      {renderRow(<FaInfo />, `Tilmeldingsfrist ${dateFormatter.format(new Date(registrationEnd))}`)}
      {renderRow(<FaMoneyBillWave />, price)}
      {renderLocation()}
    </div>
  )
}

export default EventInfo
