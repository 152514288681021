// example theme.js

let space = [0, 8, 16, 32, 64, 128, 256]
space.normal = space[2]

let sizes = []
sizes.siteWidth = 567
sizes.maxWidth = 819
sizes.smallModal = 500

export default {
  breakpoints: ['0', '52em', '64em'],
  // breakpoints: ['40em', '52em', '64em'],
  // breakpoints: {
  //   xs: 0,
  //   sm: 576,
  //   md: 768,
  //   lg: 992,
  //   xl: 1200
  // },
  fontSizes: [10, 12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    primary: '#2185d0',
    primaryDark: '#1c76b9',
    footer: '#061723',
    border: '#dadce0',
    hover: '#d7e9f7',
    danger: '#da0000',
    dangerDark: '#af0000',
    warning: '#ff9800',
    mutedText: '#585858',
    muted: '#585858',
    disabled: '#9bccf1',
    link: '#2185d0',
    menu: '#2A3746',
  },
  space,
  sizes,
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  variants: {},
  text: {},
  buttons: {
    primary: {
      color: 'white',
      bg: 'primary',
    },
  },
}
