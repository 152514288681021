import React, { useState, Suspense } from 'react'
import styled from 'styled-components'
import Card, { Title } from 'components/styles/Card'
import Carousel from 'components/Carousel'
import Box from 'components/styles/Box'
import Button from 'components/styles/Button'
import { Footer } from 'components/styles/Card'
import SubscribeModal from '../SubscribeModalV2'
import useAuthModals from 'components/userManagement/Register/useAuthModals'
import useUser from 'components/userManagement/useUser'
import AdminSignUpModal from '../AdminSignUp/AdminSignUpModal'
import roles from 'roles'
const EditPlan = React.lazy(() => import('./../EditPlan'))

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

const Info = ({ plan, canEdit, authenticated }) => {
  const { setShowRegister } = useAuthModals((p) => ({
    setShowRegister: p.setShowRegister,
  }))
  const [showAdminSignUp, setShowAdminSignUp] = useState<boolean>(false)
  const { user } = useUser()
  const [show, setShow] = useState(false)

  const { name, description, images, slug } = plan

  const handleShowSubscribe = () => {
    if (authenticated) {
      setShow(true)
    } else {
      setShowRegister(true)
    }
  }

  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <Card mt>
        <Carousel images={images} />
        <Box p={'normal'}>
          <Flex>
            <Title>{name}</Title>
            {canEdit && (
              <Suspense fallback={null}>
                <EditPlan slug={slug} />
              </Suspense>
            )}
          </Flex>
          <Box py={'normal'}>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Box>

          <Footer>
            {user.roles.includes(roles.cashier) && (
              <Button mr="2" outline onClick={() => setShowAdminSignUp(true)}>
                Admin tilmeld
              </Button>
            )}
            <Button onClick={handleShowSubscribe}>Tilmeld</Button>
          </Footer>
        </Box>
      </Card>
      {show && (
        <SubscribeModal onClose={handleClose} show={show} planId={plan.id} />
      )}
      {showAdminSignUp && (
        <AdminSignUpModal
          planId={plan.id}
          planName={plan.name}
          onClose={() => setShowAdminSignUp(false)}
          nextPaymentDate={new Date(plan.nextBilling)}
        />
      )}
    </>
  )
}

export default Info
