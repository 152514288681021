import React, { useState, Suspense } from 'react'
import useSWR from 'swr'
import { Box } from '@xstyled/styled-components'
import Button from 'components/styles/Button'
import Card from 'components/styles/Card'

import request from '../tools/request'

import Loading from 'components/loading'

const Uploader = React.lazy(() => import('../Carousel/Uploader'))
const Editor = React.lazy(() => import('../basic/EditorV2'))

const EditFrontPage = ({ onClose }) => {
  const { data, mutate } = useSWR('infoCard/frontpage')
  const [text, setText] = useState(data.body)
  const [inProgress, setInProgress] = useState(false)

  const handleSave = async () => {
    try {
      setInProgress(true)
      const resp = await request.put('infoCard/frontpage', { body: text })

      await mutate((data) => ({ ...data, ...resp.data }))
      setInProgress(false)
      onClose()
    } catch (_) {
      setInProgress(false)
    }
  }

  const handleUpdateImages = async (images) => {
    try {
      await request.put(`infoCard/frontpage/images`, { images })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Suspense fallback={<Loading />}>
      <Card mt>
        <Editor initialValue={text} onChange={(html) => setText(html)} />
      </Card>
      <Box display="flex" justifyContent="flex-end" mt="2">
        <Button text onClick={onClose} disabled={inProgress}>
          Fortryd
        </Button>
        <Button loading={inProgress} onClick={handleSave}>
          Gem
        </Button>
      </Box>
      <Uploader
        images={data.images}
        type="frontpage"
        clubId={'ajk'}
        onChange={handleUpdateImages}
      />
    </Suspense>
  )
}

export default EditFrontPage
