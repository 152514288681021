import styled, {
  backgrounds,
  basics,
  borders,
  flexboxes,
  grids,
  layout,
  positioning,
  shadows,
  space,
  typography,
  xgrids,
} from '@xstyled/styled-components'

const Text = styled('div')(
  backgrounds,
  basics,
  borders,
  flexboxes,
  grids,
  layout,
  positioning,
  shadows,
  space,
  typography,
  xgrids,
)
export default Text
