import { useEffect, useState } from 'react'
import { useCreateFetch } from 'components/tools/useFetch'
import useStore from './store'

const usePlan = (slug) => {
  const { store, initialLoadSucceeded, addPlan } = useStore()
  const { fetch, data, fetching, error, success } = useCreateFetch(
    `plans/${slug}`,
  )
  const [plan, setPlan] = useState({})
  const [succeeded, setSucceeded] = useState(false)

  useEffect(() => {
    if (
      !success &&
      !initialLoadSucceeded &&
      !fetching &&
      !error &&
      !store.has(slug)
    )
      fetch()
  }, [error, fetch, fetching, initialLoadSucceeded, slug, store, success])

  useEffect(() => {
    if (success) {
      addPlan(data)
    }
  }, [addPlan, data, success])

  useEffect(() => {
    if (store.has(slug)) {
      setPlan(store.get(slug))
      setSucceeded(true)
    }
  }, [data, slug, store])

  return { plan, fetching, error, success: succeeded }
}

export default usePlan
