import React, { useState } from 'react'
import useSWR from 'swr'

import Box from 'components/styles/Box'
import Button from 'components/styles/Button'
import Card2 from 'components/styles/Card2'
import Text from 'components/styles/Text'
import ConfirmUnsubscribe from './ConfirmUnsubscribe'
import useUser from 'components/userManagement/useUser'
import roles from 'roles'
import ChangeSubscription from './ChangeSubscription'

type Props = { planId?: number }

type Member = {
  uid: string
  personId: number
  planId: number
  userId: number
  currentPeriodEnd: string
  stripeuid: string
  adminSignUp: boolean
  firstName: string
  lastName: string
  ownerId: number
  gender: string
  birth: string
  city: string
  email: string
  phoneNumber: string
} & (
  | {
      cancellationDate: null
      status: 'active' | 'pending' | 'legacy'
    }
  | {
      cancellationDate: string
      status: 'canceled'
    }
)

type Unsubscribe = {
  planName: string
  personName: string
  subscriptionUid: string
}
type Change = {
  currentPlanId: number
  subscriptionUid: string
  personName: string
}

const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

const AdminSubscriptions: React.FC<Props> = ({ planId }) => {
  const { user } = useUser()

  const { data = [], mutate } = useSWR<Member[]>('subscription-v2/all', {
    suspense: false,
  })

  const members =
    planId !== undefined ? data.filter((s) => s.planId === planId) : data

  const [unsubscribeState, setUnsubscribeState] = useState<Unsubscribe | null>(
    null,
  )
  const [changeSubscriptionState, setChangeSubscriptionState] =
    useState<Change | null>(null)

  const handleUnsubscribe = (
    uid: string,
    personName: string,
    planName: string,
  ) => {
    setUnsubscribeState({ subscriptionUid: uid, personName, planName })
  }
  const handleChangeSubscription = (
    uid: string,
    personName: string,
    currentPlanId: number,
  ) => {
    setChangeSubscriptionState({
      subscriptionUid: uid,
      currentPlanId,
      personName,
    })
  }

  const renderCard = (s: Member) => {
    const {
      firstName,
      lastName,
      uid,
      birth,
      city,
      email,
      phoneNumber,
      planId,
      status,
    } = s
    const personName = `${firstName} ${lastName}`

    const birthDate = new Date(birth)

    const age = Math.round((Date.now() - birthDate.getTime()) / 31557600000) //Number of ms in a year
    return (
      <Card2 m="2" p="2" key={uid}>
        <Text fontSize="4">{personName}</Text>
        <Text mb="1" color={status === 'canceled' ? '#ae0303' : 'muted'}>
          {status}
        </Text>
        <Text>{`${birthDate.toLocaleDateString('da', options)} - ${age} år`}</Text>
        <Text>{city}</Text>
        <Text my="1">
          <a href={`mailto:${email}`}>{email}</a>
        </Text>
        <Text>
          <a href={`tel:${email}`}>{phoneNumber}</a>
        </Text>
        {user.roles.includes(roles.cashier) && (
          <Box mt="2" display="flex" justifyContent="space-around">
            <Button
              disabled={status !== 'active'}
              outline
              warning
              onClick={() => handleChangeSubscription(uid, personName, planId)}
            >
              Flyt hold
            </Button>
            <Button
              disabled={status !== 'active'}
              outline
              danger
              onClick={() => handleUnsubscribe(uid, personName, 'planName')}
            >
              Udmeld
            </Button>
          </Box>
        )}
      </Card2>
    )
  }

  return (
    <>
      <Text fontSize="5" ml="2" mt="3">
        Alle tilmeldinger ({members.length})
      </Text>
      <Box display="grid" gridTemplateColumns={['1fr', '1fr 1fr']}>
        {members?.map(renderCard)}
      </Box>
      {unsubscribeState && (
        <ConfirmUnsubscribe
          {...unsubscribeState}
          onClose={() => {
            mutate((data) =>
              data?.filter((p) => p.uid !== unsubscribeState.subscriptionUid),
            )
            setUnsubscribeState(null)
          }}
        />
      )}
      {changeSubscriptionState && (
        <ChangeSubscription
          {...changeSubscriptionState}
          onClose={() => {
            mutate((data) =>
              data?.map((p) =>
                p.uid !== changeSubscriptionState.subscriptionUid
                  ? {
                      ...p,
                      status: 'canceled',
                      cancellationDate: new Date().toJSON(),
                    }
                  : p,
              ),
            )
            setChangeSubscriptionState(null)
          }}
        />
      )}
    </>
  )
}

export default AdminSubscriptions
