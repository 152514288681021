import create from 'zustand'

const useAuthModals = create<any, any, any, any>((set) => ({
  showLogin: false,
  showRegister: false,

  setShowLogin: (val: boolean) => set(() => ({ showLogin: val })),
  setShowRegister: (val: boolean) => set(() => ({ showRegister: val })),
}))

export default useAuthModals
