import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import List from './List'
import useFailedChargesStore from './state'
import { LoadingHigh } from '../../../loading'
import Modal from '../../../basic/Modal'

const Container = styled.div`
  margin: 2em;
`
const Item = styled.div`
  margin: 1em 0;
`
const Title = styled.h3`
  margin: 0em;
`

const FailedCharges = () => {
  const { fetch } = useFailedChargesStore(state => state.actions)
  const { error, fetching, success, store } = useFailedChargesStore(state => ({
    error: state.error,
    fetching: state.fetching,
    success: state.success,
    store: state.store
  }))

  const [customer, setCustomer] = useState({})
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    fetch()
  }, [fetch])

  const handleClick = customer => {
    setCustomer(customer)
    setShowModal(true)
  }

  const renderItem = (name, value) => (
    <Item>
      <Title>{name}</Title>
      {value}
    </Item>
  )

  const renderModalContent = () => {
    const { created, customerId, email, userName, failure_code, failure_message, invoice, outcome = {} } = customer
    const { network_status, reason, risk_level, seller_message, risk_score, type } = outcome

    return (
      <Container>
        {renderItem('created', created)}
        {renderItem('customerId', customerId)}
        {renderItem('email', email)}
        {renderItem('userName', userName)}
        {renderItem('failure_code', failure_code)}
        {renderItem('failure_message', failure_message)}
        {renderItem('invoice', invoice)}
        {renderItem('network_status', network_status)}
        {renderItem('reason', reason)}
        {renderItem('network_status', network_status)}
        {renderItem('risk_level', risk_level)}
        {renderItem('seller_message', seller_message)}
        {renderItem('risk_score', risk_score)}
        {renderItem('type', type)}
      </Container>
    )
  }

  if (fetching) {
    return <LoadingHigh />
  } else if (success) {
    return (
      <>
        <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
          {renderModalContent()}
        </Modal>
        <List data={store.data} timestamp={store.timestamp} onClick={handleClick} />
      </>
    )
  } else {
    return <div>{error && error.message}</div>
  }
}

export default FailedCharges
