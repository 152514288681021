import './style.css'
import App from 'App'
import Axios from 'axios'
import React from 'react'
import render from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { SWRConfig } from 'swr'
import theme from './theme'

const loadUser = () => {
  try {
    return JSON.parse(localStorage.getItem('user') || '')
  } catch (_) {
    return {}
  }
}

const user = loadUser()
if (user?.token) {
  Axios.defaults.headers.common.Authorization = `Bearer ${user.token}`
}

// Initilazing our web api globally production
if (process.env.NODE_ENV === 'production') {
  Axios.defaults.headers.common.clubId = 'ajk'
} else {
  Axios.defaults.headers.common.clubId = 'ajkTest'
  // Axios.defaults.headers.common.clubId = 'ajk'
}

export const fetcher = (url: string) =>
  Axios.get(`/api/${url}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data
    })

render.render(
  <SWRConfig value={{ suspense: false, fetcher }}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </SWRConfig>,
  document.getElementById('root'),
)
