import create from 'zustand'

const mapper = plans => new Map(plans.map(p => [p.slug, p]))

const [plansStore] = create((set, get) => ({
  store: new Map(),
  initialLoadSucceeded: false,
  // updatePlan: (person, planSlug) => {
  //     const persons = get().store.get(planSlug)
  //     set(() => ({ store: new Map(get().store.set(planSlug, [person, ...persons])) }))
  // },
  addPlan: plan => set(() => ({ store: new Map(get().store.set(plan.slug, plan)) })),
  setAll: plans => set(() => ({ store: mapper(plans) })),
  setInitialLoadSucceeded: () => set(() => ({ initialLoadSucceeded: true }))
}))

export default plansStore
