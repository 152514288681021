import styled from '@xstyled/styled-components'
import { A } from 'hookrouter'
import React from 'react'
import { MdEdit } from 'react-icons/md'

import RoleAuth from '../basic/roleAuth.jsx'
import Card, { Actions } from '../styles/Card'
import { borderRadius, colors } from '../styles/styles'

const Img = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0 0;
`
const Body = styled.div`
  padding: 1em 2em 2em 2em;
  border-radius: 0 0 ${borderRadius} ${borderRadius};
`
const Title = styled.h1`
  font-weight: 500;
  color: ${colors.footer};
  margin: 0;
  font-size: 5;
`
const Subtitle = styled.h2`
  font-weight: 400;
  color: #2e3548;
  margin: 0;
  margin-bottom: 1em;
  font-size: 1em;
  font-style: italic;
`

const ImageCard = ({ image, title, subtitle, text, link, url, onEdit, onDelete }) => {
  return (
    <Card fullWidth mt>
      {image ? <Img src={`/images/${process.env.REACT_APP_CLUB_ID}/${image}`} role="presentation" /> : null}

      <Body>
        <div>
          <RoleAuth allowedRole="news-editor">
            <Actions>
              <A href={`/news/${url}/edit`}>
                <MdEdit />
              </A>
            </Actions>
          </RoleAuth>
          <Title>{link ? <A href={`/news/${url}`}>{title}</A> : title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </Body>
    </Card>
  )
}

export default ImageCard
