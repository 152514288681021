import { useState, useCallback } from 'react'
import axios from 'axios'

const useCall = (url, method = 'POST') => {
  const [state, setstate] = useState<{
    resp: any
    inProgress: boolean
    error: null | Error
    success: boolean
  }>({
    resp: {},
    inProgress: false,
    error: null,
    success: false,
  })

  const call = useCallback(
    async (payload, onSuccess = () => {}) => {
      try {
        setstate({
          resp: {},
          inProgress: true,
          error: null,
          success: false,
        })

        const r =
          method === 'POST'
            ? await axios.post('/api/' + url, payload)
            : await axios.put('/api/' + url, payload)
        setstate({
          resp: r.data,
          inProgress: false,
          error: null,
          success: true,
        })
        onSuccess()
      } catch (error) {
        console.log(error)
        console.log(error.response.data)
        setstate({
          resp: {},
          inProgress: false,
          error: error.response.data,
          success: false,
        })
      }
    },
    [method, url],
  )

  return { ...state, call }
}

export default useCall
