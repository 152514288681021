import React from 'react'
import styled, { keyframes } from 'styled-components'
import { borderRadius, colors } from './styles'

const fadeInUp = keyframes`
    0% {
      transform:translateY(10px);
        opacity: 0;
      }
      100% {
        transform:translateY(0);
        opacity: 1;
      }
`

const Container = styled.div`
  display: flex;
  justify-content: ${(p) => (p.center ? 'center' : p.right && 'flex-end')};
  margin-top: ${(p) => (p.mt ? '2em' : 0)};
  margin-left: ${(p) => (p.ml ? '16px' : 0)};
  margin-right: ${(p) => (p.mr ? '16px' : 0)};
  animation: ${(p) => p.fadeInUp && fadeInUp} 0.3s linear;
`

const getColor = (p) => {
  if (p.outline || p.text) {
    if (p.danger) return colors.danger
    if (p.warning) return colors.warning
    return colors.primary
  }
  return 'white'
}
const StyledButton = styled.button`
  cursor: ${(p) => (p.loading ? 'wait' : p.disabled ? 'not-allowed' : 'pointer')};
  border-radius: ${(p) => (p.round ? '30px' : borderRadius)};
  background-color: ${(p) => (p.outline || p.text ? 'transparent' : p.danger ? colors.danger : p.disabled ? colors.disabled : p.green ? colors.green : colors.primary)};
  border: ${(p) => (p.outline ? `${colors.border} solid 1px` : 'none')};
  padding: 11px 21px;
  font-size: 0.9em;
  color: ${(p) => getColor(p)};
  width: ${(p) => (p.fullWidth ? '100%' : 'auto')};
  white-space: nowrap;

  &:hover {
    background-color: ${(p) =>
      p.outline
        ? '#fafafa'
        : p.text
          ? 'transparent'
          : p.danger
            ? colors.dangerDark
            : p.disabled
              ? colors.disabled
              : colors.primaryDark};
  }
`

/**
 *
 * @round rounded corners
 * @loading makes it spin
 * @center center the button
 * @left
 * @right
 * @text
 * @outline
 * @fullWidth
 * @mt margin-top 2em
 * @ml margin-left 16px
 * @mr margin-left 16px
 * @fadeInUp
 * @danger
 * @warning
 * @disabled
 */
const Button = (props) => {
  const {
    onClick,
    round,
    loading,
    children,
    center,
    left,
    right,
    text,
    outline,
    fullWidth,
    mt,
    ml,
    mr,
    fadeInUp,
    danger,
    warning,
    disabled,
    green,
  } = props

  const spinner = () => (
    <div className="spinner">
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  )

  return (
    <Container
      center={center}
      left={left}
      right={right}
      mt={mt}
      ml={ml}
      mr={mr}
      fadeInUp={fadeInUp}
    >
      <StyledButton
        green={green}
        danger={danger}
        warning={warning}
        text={text}
        round={round}
        outline={outline}
        fullWidth={fullWidth}
        disabled={disabled}
        loading={loading ? 1 : 0}
        onClick={loading || disabled ? null : onClick}
      >
        {loading ? spinner() : children}
      </StyledButton>
    </Container>
  )
}

export default Button
