import { LoadingHigh } from 'components/loading'
import Box from 'components/styles/Box'
import Text from 'components/styles/Text'
import request from 'components/tools/request'
import { A } from 'hookrouter'
import React, { useEffect, useState } from 'react'
import { GoAlert } from 'react-icons/go'
import { MdCake } from 'react-icons/md'

type Props = { token: string }

const VerifyEmail: React.FC<Props> = ({ token }) => {
  const [inProgress, setInProgress] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  const validate = async (token: string) => {
    try {
      await request.post('login/verify', { token })

      setInProgress(false)
    } catch (error) {
      setError(error)
      setInProgress(false)
    }
  }

  useEffect(() => {
    validate(token)
  }, [token])

  if (error)
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" mt="4">
        <GoAlert size={170} color="#9a0909" />
        <Text>E-mailadressen kunne ikke bekræftes</Text>
        <Text>{error.message}</Text>
      </Box>
    )
  if (inProgress)
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <LoadingHigh />
        <Text>Validere e-mailadresse</Text>
      </Box>
    )

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" mt="4">
      <MdCake size={170} color="#0b3656" />
      <Text my="2">Din e-mail-adresse er nu bekræftet</Text>
      <A href="/">Til forsiden</A>
    </Box>
  )
}

export default VerifyEmail
