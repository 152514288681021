import { A } from 'hookrouter'
import styled from '@xstyled/styled-components'

const ListItem = styled(A)`
  background-color: white;
  padding: 2;
  display: flex;
  border-bottom: 1px solid;
  border-color: border;
  justify-content: space-between;
  align-items: center;
  color: black;
  cursor: pointer;
`

export default ListItem
