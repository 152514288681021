import React from 'react'
// import React, { useState, useEffect } from 'react'
import { A } from 'hookrouter'
import styled from 'styled-components'

import Card, { Title } from '../../styles/Card'
import { padding } from '../../styles/styles'
import Button from '../../styles/Button'
import useView from './viewState'
import useUser from 'components/userManagement/useUser'
import useAuthModals from 'components/userManagement/Register/useAuthModals'

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 100px;
  padding: 0 ${padding};
  white-space: pre;
`

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${padding};
`
// const Img = styled.img`
//   border-radius: 8px 8px 0 0;
//   object-fit: cover;
//   height: 200px;

//   @media only screen and (min-width: ${p => p.theme.breakpoints[2]}) {
//     height: 300px;
//   }
// `

const OverviewCard = ({ plan }) => {
  const { setShow, setPlan } = useView((s) => ({ setShow: s.setShowModal, setPlan: s.setPlan }))
  const { user } = useUser()
  const setShowRegister = useAuthModals((p) => p.setShowRegister)
  // const [url, setUrl] = useState('')

  const handleSignUp = () => {
    if (user.authenticated) {
      setPlan(plan)
      setShow(true)
    } else {
      setShowRegister(true)
    }
  }

  // const getRandomInt = max => {
  //   return Math.floor(Math.random() * Math.floor(max))
  // }

  // useEffect(() => {
  //   const url = plan.images.filter(i => i && i.name)
  //   const max = url.length

  //   if (max === 0) {
  //     setUrl('')
  //   } else if (max === 1) {
  //     setUrl(url[0].name)
  //   } else {
  //     setUrl(url[getRandomInt(max)].name)
  //   }
  // }, [plan.images])

  // const { name, shortDescription, slug, clubId } = plan
  const { name, shortDescription, slug } = plan
  return (
    <Card mt>
      {/* <Img src={`/images/${clubId}/${url}`} alt={name + ' cover picture'} /> */}
      <Title padding>{name}</Title>
      <Content>{shortDescription}</Content>
      <Buttons>
        <A href={'/teams/' + slug}>
          <Button outline>Læs mere</Button>
        </A>
        <Button ml onClick={handleSignUp}>
          Tilmeld
        </Button>
      </Buttons>
    </Card>
  )
}

export default OverviewCard
