import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'

/**
 * Automatic fetch
 * @param {string} url the part efter api/
 */
const useFetch = (url, defaultValue = {}) => {
  const [data, setData] = useState(defaultValue)
  const [success, setSuccess] = useState(false)
  const [fetching, setFetching] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchUrl = async () => {
      try {
        const resp = await axios.get('/api/' + url)

        setData(resp.data)
        setFetching(false)
        setSuccess(true)
      } catch (error) {
        console.log(error)
        setError(error.response.data)
        setFetching(false)
      }
    }

    fetchUrl()
  }, [url])

  return { data, fetching, error, success }
}

const obj = {}
/**
 * Manuel call fetch()
 * @param {string} url the part efter api/
 */
const useCreateFetch = url => {
  const [state, setState] = useState({ fetching: false, success: false, error: null, data: obj })

  const fetch = useCallback(async () => {
    try {
      setState({ fetching: true, success: false, error: null, data: obj })
      const resp = await axios.get('/api/' + url)
      setState({ fetching: false, success: true, error: null, data: resp.data })
    } catch (error) {
      console.log(error)
      setState({ fetching: false, success: false, error: error.response && error.response.data, data: obj })
    }
  }, [url])

  return { fetch, ...state }
}

export default useFetch
export { useFetch, useCreateFetch }
