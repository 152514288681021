import { Box } from '@xstyled/styled-components'
import ErrorText from 'components/basic/error'
import RoleAuth from 'components/basic/roleAuth'
import { LoadingHigh } from 'components/loading'
import { Title } from 'components/styles/Card'
import useUser from 'components/userManagement/useUser'
import { A } from 'hookrouter'
import React from 'react'
import { MdEdit } from 'react-icons/md'
import roles from 'roles'
import useSWR from 'swr'

import Card, { Actions } from '../styles/Card'
import Calendar from './calendar/calendar'

const InfoPage = ({ slug }) => {
  const { data, error } = useSWR(`info/${slug}`)
  const { user } = useUser()

  if (error?.statusCode === 404 && user.roles.includes(roles.editor))
    return (
      <Box display="flex" alignItems="center" my="6" flexDirection="column">
        <Title>Siden findes ikke</Title>
        <A href={`/info/${slug}/new`}>Opret side</A>
      </Box>
    )

  if (error) return <ErrorText>{error.message}</ErrorText>
  if (!data) return <LoadingHigh />

  const { title, body, calendar, iCalLink, googleCalendarLink } = data
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box width="100%">
        <Card fullWidth mt>
          <Box p="2">
            <RoleAuth allowedRole="news-editor">
              <Actions>
                <A href={`/info/${slug}/edit`}>
                  <MdEdit />
                </A>
              </Actions>
            </RoleAuth>
            <Title>{title}</Title>
            <div dangerouslySetInnerHTML={{ __html: body }} />
            {iCalLink && <a href={iCalLink}>[Download iCal]</a>} {googleCalendarLink && <a href={googleCalendarLink}>[Se på Google Kalender]</a>}
          </Box>
        </Card>
        <Calendar calendar={calendar} />
      </Box>
    </Box>
  )
}

export default InfoPage
