import React, { FC, ReactElement } from 'react'
import styled from 'styled-components'
import Modal from './Modal'

import Padding from '../../styles/Padding'
import Button from '../../styles/Button'

const ModalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const ModalText = styled.h1`
  font-weight: 400;
  font-size: 1.5em;
`
const ErrorText = styled.p`
  margin: 1em 0 0 0;
  text-align: right;
  color: red;
  font-style: italic;
`

const noop = () => {}

type Props = {
  isOpen: boolean
  danger?: boolean
  onClose?: () => void
  onConfirm?: () => void
  inProgress?: boolean
  primaryText: ReactElement | string
  text: ReactElement | string
  error: Error | null
}

const Confirm: FC<Props> = ({
  isOpen,
  danger,
  onClose = noop,
  inProgress,
  onConfirm = noop,
  primaryText = '',
  text = '',
  error,
}) => {
  return (
    <Modal dialog isOpen={isOpen} toggle={onClose} persistent={inProgress}>
      <Padding>
        <ModalText>{text}</ModalText>
        <ModalRow>
          <Button mt text onClick={onClose} disable={inProgress}>
            Fotryd
          </Button>
          <Button
            mt
            danger={danger}
            round
            onClick={onConfirm}
            loading={inProgress}
          >
            {primaryText}
          </Button>
        </ModalRow>
        <ErrorText>{error?.message}</ErrorText>
      </Padding>
    </Modal>
  )
}

export default Confirm
