import React, { FC, Suspense } from 'react'
import Modal from 'components/basic/Modal/Modal'
import { LoadingHigh } from 'components/loading'
const SignUp = React.lazy(() => import('./SignUp'))
type Props = {
  slug: string
  isFullPayment?: boolean
  onClose: () => void
}
const SignUpModal: FC<Props> = ({ slug, onClose }) => {
  return (
    <Modal isOpen={true} toggle={onClose}>
      <Suspense fallback={<LoadingHigh />}>
        <SignUp slug={slug} onClose={onClose} />
      </Suspense>
    </Modal>
  )
}

export default SignUpModal
