import { Box } from '@xstyled/styled-components'
import LinkCapture from 'components/basic/linkCapture'
import { LoadingHigh } from 'components/loading'
import Button from 'components/styles/Button'
import Card from 'components/styles/Card'
import { Title } from 'components/styles/Card'
import Text from 'components/styles/Text'
import useAuthModals from 'components/userManagement/Register/useAuthModals'
import useUser from 'components/userManagement/useUser'
import React, { useEffect, useState } from 'react'
import { FaCopy, FaEdit } from 'react-icons/fa'
import roles from 'roles'
import useSWR, { useSWRConfig } from 'swr'

import CreateUpdateEvent from '../CreateUpdate'
import EventInfo from '../EventInfo'
import { isExpired } from '../isExpired'
import SignUpModal from '../SignUpModal'
import Participants from './Participants'
import AdminParticipants from './Participants/AdminParticipants'
import MyParticipants from './Participants/MyParticipants'

const Single = ({ slug }) => {
  const { data, error, mutate } = useSWR(`events/${slug}`)
  const { cache } = useSWRConfig()
  const { user } = useUser()
  const setShowLogin = useAuthModals((p) => p.setShowLogin)

  const [isOpen, setIsOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isCopying, setIsCopying] = useState(false)

  useEffect(() => {
    if (!data) {
      const c = cache as any
      const { events = [] } = c.has('events') && c.get('events')
      const cachedEvent = events.find((e) => e.slug === slug)
      if (cachedEvent) mutate(cachedEvent)
    }
  }, [cache, data, mutate, slug])

  const handleSignUp = () => {
    if (user.authenticated) {
      setIsOpen(true)
    } else {
      setShowLogin(true)
    }
  }

  if (error)
    return (
      <Text my="4" mx="2" fontSize={[2, 3]} color="muted" textAlign="center">
        {error.message}
      </Text>
    )
  if (!data) return <LoadingHigh />

  const {
    name,
    description,
    registrationEnd,
    location,
    price,
    startDateTime,
    endDateTime,
    myParticipants,
    participants,
  } = data || {}
  const customFields = data?.customFields?.map((v) => v.name)

  return (
    <>
      <div>
        <Card mt>
          {user.roles.includes(roles.eventManger) && (
            <Box display="flex" justifyContent="flex-end">
              <Box p="1">
                <FaCopy onClick={() => setIsCopying(true)} />
              </Box>
              <Box p="1">
                <FaEdit onClick={() => setIsEditing(true)} />
              </Box>
            </Box>
          )}
          <Box p="2">
            <Title>{name}</Title>
            <EventInfo
              startDateTime={startDateTime}
              endDateTime={endDateTime}
              price={price}
              registrationEnd={registrationEnd}
              location={location}
            />
          </Box>
          <Box p="2">
            <LinkCapture>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </LinkCapture>
          </Box>
          <Box p="2">
            <Button
              right
              onClick={handleSignUp}
              disabled={isExpired(registrationEnd)}
            >
              Tilmeld
            </Button>
          </Box>
        </Card>
        {user.authenticated && (
          <>
            <MyParticipants
              persons={myParticipants}
              customFields={customFields}
            />
            {user.roles.some((r) =>
              [roles.eventManger, roles.coach].includes(r),
            ) ? (
              <AdminParticipants
                persons={participants}
                customFields={customFields}
              />
            ) : (
              <Participants
                persons={participants}
                customFields={customFields}
              />
            )}
          </>
        )}
      </div>
      {isOpen && <SignUpModal slug={slug} onClose={() => setIsOpen(false)} />}
      {isEditing && (
        <CreateUpdateEvent
          isEditing
          slug={slug}
          onClose={() => setIsEditing(false)}
        />
      )}
      {isCopying && (
        <CreateUpdateEvent
          isEditing={false}
          slug={slug}
          onClose={() => setIsCopying(false)}
        />
      )}
    </>
  )
}

export default Single
