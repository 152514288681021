import React, { useState, Suspense } from 'react'
import styled, { keyframes } from 'styled-components'

import { colors } from '../styles/styles'
const DatePicker = React.lazy(() => import('react-date-picker'))

const Wrapper = styled.div`
  margin: 0;
  border: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  width: 100%;
  flex-direction: column;
  vertical-align: top;
  transition: border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, stroke 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: rgba(0, 0, 0, 0.54);
  border-color: #c4c4c4;
  margin-bottom: 20px;
  margin-right: ${(p) => (p.marginRight ? '20px' : 0)};

  svg {
    g {
      stroke: #c4c4c4;
    }
  }
  ${(p) =>
    p.disabled ||
    `
  :hover {
    color: #212121;
    border-color: #212121;
    svg {
      g {
        stroke: #212121;
      }
    }
  }`}

  ${(p) =>
    p.focus &&
    `
  color: ${colors.primary} !important; 
  svg {
      g {
        stroke: ${colors.primary} !important;
      }
    }
  `}

  .react-date-picker {
    width: 100%;
  }

  .react-date-picker__wrapper {
    border: none;
    display: flex;
    width: 100%;
  }
  .react-date-picker__inputGroup {
    font-size: 14px;
    display: block;
  }

  .react-date-picker__inputGroup__input:invalid {
    border-radius: 3px;
  }

  .react-date-picker__calendar {
    z-index: 10;
  }

  .react-calendar {
    border-radius: 6px;
    border-color: #c4c4c4;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }

  .react-date-picker--disabled {
    background-color: #fff;
  }
`
const Label = styled.div`
  transform: translate(1px, -10px) scale(0.75);
  top: 0;
  left: 0;
  position: absolute;
  background-color: white;
  padding: 0 5px;
  display: block;
`

const Border = styled.div`
  padding: 13.5px 14px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #c4c4c4;
  ${(p) =>
    p.disabled ||
    `
  :hover {
    border-color: #212121;
  }
  `}

  ${(p) => p.focus && `border-color: ${colors.primary} !important; border-width: 2px`}
`

const pulse = keyframes`
  0% {
    background-color: #fff
  }
  50% {
    background-color: #f2f2f2
  }
  100% {
    background-color: #fff
  }
`

const Loader = styled.div`
  height: 54px;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #c4c4c4;
  border-radius: 3px;
  animation: ${pulse} 2s linear infinite;
  margin-bottom: 20px;
  cursor: loading;
  cursor: progress;
  margin-right: ${(p) => (p.marginRight ? '20px' : 0)};
`

const MyDatePicker = ({ label, onChange, value, birth, mr, disabled, name }) => {
  const [focus, setFocus] = useState(false)

  return (
    <Suspense fallback={<Loader marginRight={mr} disabled={disabled} />}>
      <Wrapper focus={focus} marginRight={mr}>
        <Label>{label}</Label>
        <Border focus={focus} disabled={disabled}>
          <DatePicker
            onCalendarOpen={() => setFocus(true)}
            onCalendarClose={() => setFocus(false)}
            clearIcon={null}
            view={birth ? 'decade' : 'month'}
            maxDate={birth && new Date()}
            onChange={(value) => onChange({ target: { value } })}
            value={value}
            disabled={disabled}
            name={name}
          />
        </Border>
      </Wrapper>
    </Suspense>
  )
}

export default MyDatePicker
