import Box from 'components/styles/Box'
import Button from 'components/styles/Button'
import Card from 'components/styles/Card'
import Text from 'components/styles/Text'
import React, { useState } from 'react'
import useSWR from 'swr'
import { Plan } from 'types'

import SubscribeModalV2 from 'components/plans/SubscribeModalV2'

type Props = {
  // setPlan: (key: number) => void
  onClose: () => void
}

const ChoosePlan: React.FC<Props> = ({ onClose }) => {
  const { data = [] } = useSWR<Plan[]>('plans', { suspense: true })
  const [planId, setPlanId] = useState<number | null>(null)

  const renderPlan = (plan: Plan) => {
    const { name, id, shortDescription } = plan
    return (
      <Box m="2" key={id} onClick={() => setPlanId(id)}>
        <Card padding>
          <Text fontSize="1.5rem">{name}</Text>
          <Text>{shortDescription}</Text>
        </Card>
      </Box>
    )
  }

  return (
    <Box pb="3">
      <Text m="2" fontSize="1.6rem">
        Vælg det hold du gerne vi tilmelde dig
      </Text>

      {data.map(renderPlan)}

      <Button center text onClick={onClose}>
        Spring over
      </Button>
      {planId !== null && (
        <SubscribeModalV2
          showOneMore={true}
          show={true}
          onClose={(oneMore) => {
            setPlanId(null)
            if (oneMore !== true) {
              onClose()
            }
          }}
          planId={planId}
        />
      )}
    </Box>
  )
}

export default ChoosePlan
