import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import Card from '../styles/Card'
import Padding from '../styles/Padding'
import { siteWidth } from '../styles/styles'
import Button from '../styles/Button'
import Input from '../styles/Input'
import DatePicker from '../basic/DatePicker'
import Editor from '../basic/EditorV2'
import Modal from '../basic/Modal'
import ImageUploader from '../basic/ImageUploader'
import { navigate } from 'hookrouter'
import useSWR, { mutate as globalMutate } from 'swr'
import { LoadingHigh } from 'components/loading'
import { Box } from '@xstyled/styled-components'
import request from 'components/tools/request'
import ErrorText from 'components/basic/error'

const Content = styled.div`
  padding: 1em;
`
const InputRow = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-column-gap: 1em;
  @media only screen and (max-width: ${siteWidth}) {
    grid-template-columns: auto;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`

const ModalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const ModalText = styled.p`
  font-weight: 400;
  font-size: 1.2em;
  margin: 0;
`

const CreateUpdateArticle = ({ slug }) => {
  const {
    data: article,
    error: loadError,
    mutate,
  } = useSWR(slug ? `news/${slug}` : null, { suspense: false })

  const isNew = !slug

  const [headline, setHeadline] = useState('')
  const [text, setText] = useState('')
  const [publishDate, setPublishDate] = useState(new Date())
  const [image, setImage] = useState({ name: '', width: 0, height: 0 })
  const [showModal, setShowModal] = useState(false)

  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (article) {
      const { headline, publishDate, image, imageWidth, imageHeight, text } =
        article

      setHeadline(headline)
      setText(text)
      setPublishDate(new Date(publishDate))
      setImage({ name: image, width: imageWidth, height: imageHeight })
    }
  }, [article])

  const handleSave = async () => {
    if (isNew) {
      await handleCreate()
    } else {
      await handleUpdate()
    }
  }
  const handleUpdate = async () => {
    const payload = { headline, text, publishDate, picture: image }
    try {
      setInProgress(true)

      const { data } = await request.put(`news/${article.id}`, payload)
      mutate(data)
      setInProgress(false)
      navigate(`/news/${slug}`)
    } catch (error) {
      setError(error)
      setInProgress(false)
    }
  }

  const handleCreate = async () => {
    const payload = { headline, text, publishDate, picture: image }
    try {
      setInProgress(true)

      const { data } = await request.post('news', payload)
      globalMutate(`news/${data.slug}`, data)
      setInProgress(false)
      navigate(`/news/${data.slug}`)
    } catch (error) {
      setError(error)
      setInProgress(false)
    }
  }

  const cancel = () => {
    if (isNew) navigate(`/news`)
    else navigate(`/news/${slug}`)
  }
  const handleDelete = async () => {
    try {
      setInProgress(true)
      const { id } = article
      await request.del(`news/${id}`)
      globalMutate('news', (feed) =>
        feed?.filter((article) => !(article.id === id)),
      )
      setInProgress(false)
      navigate('/news')
    } catch (error) {
      setInProgress(false)
      setError(error)
    }
  }
  if (loadError) return <ErrorText>{error.message}</ErrorText>
  if (!isNew && !article) return <LoadingHigh />

  return (
    <>
      <Card mt>
        <ImageUploader
          isNew={isNew}
          value={image}
          onChange={setImage}
          clubId="ajk"
        />
        <Content>
          <InputRow>
            <Input
              label="Overskrift"
              type="text"
              value={headline}
              onChange={(event) => setHeadline(event.target.value)}
            />
            <DatePicker
              label="Publicerings dato"
              value={publishDate}
              onChange={(event) => setPublishDate(event.target.value)}
            />
          </InputRow>

          <Box
            border="solid 1px"
            borderColor="border"
            borderRadius="3px"
            mt="2"
            pt="5px"
          >
            <Editor
              height="auto"
              onChange={(value) => setText(value)}
              initialValue={article?.text}
            />
          </Box>
          <Buttons>
            <Row>
              <Button mt text onClick={cancel} disable={inProgress}>
                Fortryd
              </Button>
              <Button mt round onClick={handleSave} loading={inProgress}>
                {isNew ? 'Opret' : 'Opdater'}
              </Button>
            </Row>
            {isNew || (
              <Button
                mt
                danger
                round
                onClick={() => setShowModal(true)}
                disable={inProgress}
              >
                Slet
              </Button>
            )}
          </Buttons>
          {error && error.message}
        </Content>
      </Card>
      <Modal
        dialog
        isOpen={showModal}
        toggle={() => setShowModal(false)}
        persistent={inProgress}
      >
        <Padding>
          <ModalText>Er du sikker på du vil slette nyheden?</ModalText>
          <ModalRow>
            <Button
              mt
              text
              onClick={() => setShowModal(false)}
              disable={inProgress}
            >
              Fortryd
            </Button>
            <Button mt danger round onClick={handleDelete} loading={inProgress}>
              Slet
            </Button>
          </ModalRow>
        </Padding>
      </Modal>
    </>
  )
}

export default CreateUpdateArticle
