import React, { useState, FC } from 'react'
import Modal from 'components/basic/Modal/Modal'
import Input from 'components/styles/Input'
import MyDatePicker from 'components/basic/DatePicker'
import Button from 'components/styles/Button'
import Box from 'components/styles/Box'
import ErrorText from 'components/basic/error'
import { Title } from 'components/styles/Card'
import request from 'components/tools/request'
import useSWR from 'swr'
import type { Person } from './PersonChooser'
const genders = [
  {
    value: 'male',
    label: 'Mand',
  },
  {
    value: 'female',
    label: 'Kvinde',
  },
]
type Props = {
  setPerson?: (personId: string) => void
  onClose: () => void
}
const CreatePerson: FC<Props> = ({ setPerson, onClose }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [birth, setBirth] = useState(null)
  const [gender, setGender] = useState('')

  const [inProgress, setInProgress] = useState<boolean>(false)
  const [error, setError] = useState<null | Error>(null)

  const { mutate } = useSWR<Person[]>('persons', {
    dedupingInterval: Number.POSITIVE_INFINITY,
  })

  const handleCreatePerson = async () => {
    try {
      setInProgress(true)
      setError(null)

      const { data } = await request.post<{ person: Person }>('persons', {
        firstName,
        lastName,
        birth,
        gender,
      })
      await mutate((p) => {
        if (!p) return p
        return [data.person, ...p]
      }, false)
      setInProgress(false)

      setPerson?.(data.person.id.toString())
      onClose()
    } catch (error) {
      setInProgress(false)
      setError(error)
    }
  }

  return (
    <Modal isOpen={true} toggle={onClose} persistent={inProgress} dialog>
      <Box p={2}>
        <Title>Opret Person</Title>
        <Input
          my={2}
          type="text"
          label="Fornavn"
          name="firstName"
          disabled={inProgress}
          value={firstName}
          onChange={(e) => setFirstName(e.currentTarget.value)}
        />
        <Input
          my={2}
          type="text"
          label="Efternavn"
          name="lastName"
          disabled={inProgress}
          value={lastName}
          onChange={(e) => setLastName(e.currentTarget.value)}
        />

        <MyDatePicker
          mr
          label="Fødselsdato"
          disabled={inProgress}
          value={birth}
          onChange={(e) => setBirth(e.target.value)}
          name="birth"
          birth
        />
        <Input
          my={2}
          select
          label={'Køn'}
          options={genders}
          disabled={inProgress}
          onChange={(e) => setGender(e.currentTarget.value)}
          value={gender}
        />

        {error && <ErrorText>{error.message}</ErrorText>}
        <Button loading={inProgress} center round onClick={handleCreatePerson}>
          Opret
        </Button>
      </Box>
    </Modal>
  )
}

export default CreatePerson
