import ErrorText from 'components/basic/error'
import { A } from 'hookrouter'
import React from 'react'
import useSWRInfinite from 'swr/infinite'
import roles from '../../roles'
import RoleAuth from '../basic/roleAuth'
import Button from '../styles/Button'
import ImageCard from './ImageCard'
import { subtitleCreator } from './utils'

const LIMIT = 20

const NewsFeed = () => {
  const { data, error, size, setSize, isValidating } = useSWRInfinite(
    (index, previousPageData) => {
      if (previousPageData && previousPageData.length < LIMIT) return null
      return `news?offset=${index * LIMIT}&limit=${LIMIT}`
    },
  )
  const isReachingEnd = data && data[data.length - 1]?.length < LIMIT

  return (
    <div>
      <RoleAuth allowedRole={roles.newsEditor}>
        <A href="/news/new">
          <Button fullWidth center round fadeInUp mt>
            Opret
          </Button>
        </A>
      </RoleAuth>
      {data?.map((articles) =>
        articles.map((article) => {
          const {
            id,
            headline,
            text,
            firstName,
            lastName,
            publishDate,
            image,
            picture,
            slug,
          } = article
          const subtitle = subtitleCreator(
            firstName,
            lastName,
            new Date(publishDate),
          )
          return (
            <ImageCard
              key={id}
              title={headline}
              link
              subtitle={subtitle}
              text={text}
              image={image || picture}
              url={slug}
            />
          )
        }),
      )}

      {error && <ErrorText>{error.message}</ErrorText>}

      {!isReachingEnd && data && (
        <Button
          fullWidth
          center
          round
          fadeInUp
          mt
          loading={isValidating}
          onClick={() => setSize(size + 1)}
        >
          Flere
        </Button>
      )}
    </div>
  )
}

export default NewsFeed
