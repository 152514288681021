import List from 'components/styles/List'
import ListItem from 'components/styles/ListItem'
import React from 'react'
import useSWR from 'swr'

const TeamOverview = () => {
  const { data } = useSWR('plans', { suspense: true })

  const renderLink = (plan) => {
    return (
      <ListItem key={plan.id} href={`/admin/attendees/${plan.slug}`}>
        {plan.name}
      </ListItem>
    )
  }

  return <List>{data.map(renderLink)}</List>
}

export default TeamOverview
