export const STRIPE_KEY =
  process.env.NODE_ENV === 'development'
    ? 'pk_test_m7N7PEi8ZPm4ZLP2uVgMKzXH'
    : 'pk_live_XOQ63JDp5HZwfGl4CFbo9N7O'
// export const paylikeKey = 'd7ea3000-185b-4fad-baab-5e481f4fb13c' //dev
export const paylikeKey =
  process.env.NODE_ENV === 'development'
    ? 'd7ea3000-185b-4fad-baab-5e481f4fb13c'
    : 'e1556e70-4fea-49b9-b849-bfff8df8fb99'
export default STRIPE_KEY
