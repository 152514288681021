import styled, { Box } from '@xstyled/styled-components'
import Text from 'components/styles/Text'
import React from 'react'
import throw1 from './1.png'

const Tsukuri = styled.img`
  margin-top: 50px;
  width: 150px;
  height: 200px;
  /* color: #fff; */
`
const Kuzushi = styled.img`
  margin-top: 50px;
  width: 200px;
  height: 200px;
  /* color: #fff; */
`
const Kake = styled.img`
  margin-top: 50px;
  width: 200px;
  height: 200px;
  /* color: #fff; */
`

const FrontPageV3 = () => {
  return (
    <div>
      <Box display="flex">
        <div>
          <Text fontSize="3rem" mt="3">
            Velkomen
          </Text>
          <Text fontSize="1.1rem" ml="2">
            til Århus Judo Klub
          </Text>
          <Text mt="3">Vi har hold på flere neviuer, så om du er erfaren eller helt ny</Text>
          <Text>- så har vi et godt hold, der passer til dig!</Text>
        </div>
        {/* <Kuzushi src={kuzushi}/> */}
        <Tsukuri src={throw1} />
      </Box>

      <Box mt="3">
        <Text fontSize="1.2rem">Udover judo - i alle aldersgruppe, har vi også aikido og jiu jitsu</Text>
      </Box>

      <Box display="flex">
        <Kuzushi src={throw1} />

        {/* <Tsukuri src={tsukuri} /> */}
        <div>
          <Text fontSize="2.3rem" mt="3">
            Kig forbi
          </Text>
          <Text mt="2">Du er altid velkommen til at komme forbi og prøve en træning</Text>
          <Text mt="1">Du behøver ikke at ringe først, du kan bare møde op til træningen, så hjælper vi dig godt i gang.</Text>
          {/* <Text>- så har vi et godt hold, der passer til dig!</Text> */}
        </div>
        {/* <Kuzushi src={kuzushi}/> */}
      </Box>
      <Text mt="3" fontSize="1.3rem" textAlign="center">
        De første 4 træninger er gratis!
      </Text>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Kake src={throw1} />
      </Box>

      {/* <Tsukuri src={tsukuri} /> */}
      <div>
        <Text fontSize="2rem" mt="4">
          Træningstider og hold
        </Text>
      </div>
    </div>
  )
}

export default FrontPageV3
