import React, { useEffect, Suspense, FC } from 'react'
import { useRoutes } from 'hookrouter'

import Header from './components/Header'
import Footer from './components/footer-info/Footer'
import { Container, Page } from './components/basic/pageStyle.jsx'
import useUser from 'components/userManagement/useUser'
import routes from 'routes'
import { LoadingHigh } from 'components/loading'

const App: FC = () => {
  const { refresh } = useUser()
  const routeResult = useRoutes(routes)
  useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [routeResult])

  return (
    <>
      <Header />
      <Container>
        <Page>
          <Suspense fallback={<LoadingHigh />}>{routeResult || <div>404</div>}</Suspense>
        </Page>
      </Container>
      <Footer />
    </>
  )
}

export default App
