import Input from 'components/styles/Input'
import request from 'components/tools/request'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useSWR from 'swr'

import DatePicker from '../../basic/DatePicker'
import ErrorText from '../../basic/error'
import Button from '../../styles/Button'
import Card, { Title } from '../../styles/Card'
import { colors } from '../../styles/styles'

const Grid = styled.div`
  margin: 30px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;

  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`

const AddressRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 20px;
`

const TwoRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`
const EmailVerifiedWarning = styled.span`
  font-size: 1.4em;
  color: ${colors.warning};
  font-style: italic;
`
const EmailVerifiedError = styled.span`
  font-size: 1.4em;
  color: ${colors.danger};
  font-style: italic;
`
const Resend = styled.span`
  font-size: 1.4em;
  color: ${colors.primary};
  font-style: italic;
  margin-left: 10px;
  cursor: pointer;
`
const SuccessText = styled.p`
  width: 100%;
  text-align: center;
  color: green;
`

const PersonalInfo = () => {
  const [state, setState] = useState({})
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [emailState, setEmailState] = useState({})

  const { data, mutate } = useSWR('profile')

  useEffect(() => {
    if (data) setState(data)
  }, [data])

  const handleUpdate = async () => {
    try {
      setInProgress(true)
      setError(null)
      setSuccess(false)
      const { firstName, lastName, street, zip, city, phoneNumber, birth, email } = state
      const resp = await request.put('profile', { firstName, lastName, street, zip, city, phoneNumber, birth, email })

      mutate((d) => ({ ...d, info: { ...d.info, ...resp.data } }))
      setInProgress(false)
      setSuccess(true)
    } catch (error) {
      setInProgress(false)
      setError(error)
    }
  }

  const handleVerifyEmail = async () => {
    try {
      setEmailState({ error: null, inProgress: true, success: false })
      await request.post('profile/email/verify', { email: state.email })
      setEmailState({ error: null, inProgress: false, success: true })
    } catch (error) {
      setEmailState({ error, inProgress: false, success: false })
    }
  }

  const renderEmailMessage = () => {
    if (emailState.inProgress) return <span>Sender e-mail</span>
    if (emailState.success) return <span>E-mail sendt</span>
    if (emailState.error) return <EmailVerifiedError>{emailState.error.message}</EmailVerifiedError>
    return (
      <span>
        <EmailVerifiedWarning>E-mailadresse er ikke bekræftet</EmailVerifiedWarning>
        <Resend onClick={handleVerifyEmail}>Gensend</Resend>
      </span>
    )
  }

  const handleChange = (name, value) => {
    console.log(name, value)

    setState((state) => ({ ...state, [name]: value }))
  }

  const renderInput = () => {
    return (
      <Grid>
        <Input
          type="text"
          label="Fornavn"
          name="firstName"
          autoComplete="name"
          value={state.firstName || ''}
          onChange={(e) => handleChange('firstName', e.target.value)}
          disabled={inProgress}
        />
        <Input
          type="text"
          label="Efternavn"
          name="lastName"
          autoComplete="family-name"
          value={state.lastName || ''}
          onChange={(e) => handleChange('lastName', e.target.value)}
          disabled={inProgress}
        />
        <Input
          type="text"
          label="Adresse"
          name="street"
          value={state.street || ''}
          onChange={(e) => handleChange('street', e.target.value)}
          disabled={inProgress}
        />
        <AddressRow>
          <Input type="text" label="By" name="city" value={state.city || ''} onChange={(e) => handleChange('city', e.target.value)} disabled={inProgress} />
          <Input
            type="number"
            label="Post nr"
            name="zip"
            autoComplete="postal-code"
            value={state.zip || ''}
            onChange={(e) => handleChange('zip', e.target.value)}
            disabled={inProgress}
          />
        </AddressRow>
        <TwoRow>
          <DatePicker
            mr
            label="Fødselsdato"
            value={state.birth || ''}
            onChange={(e) => handleChange('birth', new Date(e.target.value))}
            name="birth"
            birth
            disabled={inProgress}
          />
          <Input
            type="number"
            label="Telefonnummer"
            name="phoneNumber"
            autoComplete="tel"
            value={state.phoneNumber || ''}
            onChange={(e) => handleChange('phoneNumber', e.target.value)}
            disabled={inProgress}
          />
        </TwoRow>
        <Input
          type="text"
          label="E-mail"
          name="email"
          autoComplete="email"
          value={state.email || ''}
          onChange={(e) => handleChange('email', e.target.value)}
          disabled={inProgress}
          helperText={!state.emailVerified && renderEmailMessage()}
        />
      </Grid>
    )
  }

  return (
    <Card padding mt>
      <Title paddin>Profil</Title>
      {renderInput()}

      <Button center loading={inProgress} onClick={handleUpdate}>
        Gem ændringer
      </Button>
      <ErrorText>{error && error.message}</ErrorText>
      {success && <SuccessText>Informationer opdateret</SuccessText>}
    </Card>
  )
}

export default PersonalInfo
