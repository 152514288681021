import React from 'react'
import styled from 'styled-components'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

import { siteWidth, borderRadius } from '../styles/styles'

const Container = styled.div`
  .carousel .slide {
    background: transparent;
  }
`

const Image = styled.img`
  object-fit: cover;
  height: 600px;
  border-radius: ${borderRadius} ${borderRadius} 0 0;

  @media only screen and (max-width: ${siteWidth}) {
    height: 300px;
  }
`

const MyCarousel = ({ images, clubId = process.env.REACT_APP_CLUB_ID }) => {
  const image = (url, i) => {
    return (
      <div key={i}>
        <Image src={`/images/${clubId}/${url}`} alt="" />
      </div>
    )
  }

  return (
    <Container>
      <Carousel showThumbs={false} showStatus={false} interval={5000} infiniteLoop autoPlay>
        {images.filter(i => i.name).map((img, index) => image(img.name, index))}
      </Carousel>
    </Container>
  )
}

export default MyCarousel
