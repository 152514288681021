import { Box } from '@xstyled/styled-components'
import LinkCapture from 'components/basic/linkCapture'
import Button from 'components/styles/Button'
import Card from 'components/styles/Card'
import useUser from 'components/userManagement/useUser'
import React, { useState } from 'react'
import { MdEdit } from 'react-icons/md'
import roles from 'roles'
import useSWR from 'swr'

import EditInfoBox from './EditInfoBox'
import { URL_KEY } from '.'

const InfoBox = () => {
  const { user } = useUser()

  const { data } = useSWR(URL_KEY)
  const [editMode, setEditMode] = useState(false)

  const hasContent = data && !!data.info.body
  const canEdit = user.roles.includes(roles.eventManger)

  if (editMode) return <EditInfoBox onClose={() => setEditMode(false)} />

  if (hasContent)
    return (
      <Card mt>
        {canEdit && (
          <Box display="flex" justifyContent="flex-end" p="1">
            <MdEdit onClick={() => setEditMode(true)} />
          </Box>
        )}
        <Box m="2">
          <LinkCapture>
            <div dangerouslySetInnerHTML={{ __html: data?.info.body }} />
          </LinkCapture>
        </Box>
      </Card>
    )

  if (canEdit)
    return (
      <Button onClick={() => setEditMode(true)} center>
        Tilføj infoboks
      </Button>
    )
  return null
}

export default InfoBox
