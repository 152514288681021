import React, { useState, useEffect, useRef } from 'react'
import { init } from 'pell'

import 'pell/dist/pell.css'
import styled from '@xstyled/styled-components'

const EditorBtn = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin: 2;
`
const Textarea = styled.textarea`
  width: 100%;
  height: 490px;
  border: none;
  padding: 2;
  box-sizing: border-box;
`

const PellStyles = styled.div`
  .pell-content {
    min-height: 490px;
    height: ${(p) => p.height};
  }
`

const Editor = ({ onChange, initialValue = '', height = '490px' }) => {
  const ref = useRef(null)
  const [html, setHtml] = useState(initialValue)
  const [editor, setEditor] = useState(null)
  const [rawMode, setRawMode] = useState(false)

  const handleChange = (html) => {
    setHtml(html)
    onChange(html)
  }

  const handleEditorMode = () => {
    setEditor(null)
    setRawMode(false)
  }

  // useEffect(() => {
  //   console.log('initialValue', initialValue)

  //   if (editor) {
  //     // editor.content.innerHTML = initialValue
  //   }
  // }, [editor, initialValue])

  useEffect(() => {
    if (ref.current && !editor) {
      const pell = init({
        element: ref.current,
        onChange: (html) => handleChange(html),
        actions: [
          'bold',
          'underline',
          'italic',
          'strikethrough',
          'heading1',
          'heading2',
          'paragraph',
          'quote',
          'olist',
          'ulist',
          'link',
          // 'list',
          {
            name: ' html',
            icon: 'HTML',
            title: 'Custom Action',
            result: () => {
              setRawMode(true)
            },
          },
        ],
      })
      setEditor(pell)
      pell.content.innerHTML = html
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, rawMode, editor, handleChange])

  if (rawMode) {
    return (
      <div>
        <div>
          <EditorBtn onClick={handleEditorMode}>{'< Editor'}</EditorBtn>
        </div>
        <Textarea value={html} onChange={(e) => setHtml(e.target.value)} />
      </div>
    )
  } else {
    return (
      <PellStyles height={height}>
        <div ref={ref}> </div>
      </PellStyles>
    )
  }
}

export default Editor
