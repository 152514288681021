import useSWR from 'swr'

export type PersonSubscription = {
  personId: number
  uid: string
  slug: string
  planName: string
  plan: string
  planId: number
  adminSignUp: boolean
  currentPeriodEnd: string
} & (
  | {
      cancellationDate: null
      status: 'active' | 'pending' | 'legacy'
    }
  | {
      cancellationDate: string
      status: 'canceled'
    }
)

export type SubscriptionPersonsList = {
  firstName: string
  lastName: string
  id: number
  subscriptions: PersonSubscription[]
}

export const useSubscriptionPersons = () =>
  useSWR<SubscriptionPersonsList[]>('subscription-v2/persons', {
    suspense: false,
  })
