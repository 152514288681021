import axios from 'axios'
import React, { Suspense, useState } from 'react'
import { MdFileUpload } from 'react-icons/md'
import styled from 'styled-components'

import Loading from '../loading'
import { siteWidth } from '../styles/styles'
const Dropzone = React.lazy(() => import('react-dropzone'))

const Drop = styled(Dropzone)`
  width: 100%;
  min-height: 300px;
  background-color: transperant;
  @media only screen and (max-width: ${siteWidth}) {
    min-height: 200px;
  }
`

const Img = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0 0;
`

const Center = styled.div`
  text-align: center;
  top: 15%;
  left: calc(50% - 240.81px);
  position: absolute;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  color: white;
  color: white;
  stroke: black;
  svg {
    height: 170px;
    width: 170px;
  }
  @media only screen and (max-width: ${siteWidth}) {
    top: 10%;
    left: calc(50% - 162.26px);
    svg {
      height: 130px;
      width: 130px;
    }
  }
`

const ErrorMsg = styled.div`
  color: #af0000;
  text-shadow: none;
`

const Height = styled.div`
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
`

const H2 = styled.h2`
  color: white;
  @media only screen and (max-width: ${siteWidth}) {
    font-size: 1em;
  }
`

const ImageUploader = ({ clubId, value, height, width, onChange, isNew }) => {
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState('')

  const uploade = async (images) => {
    try {
      const [image] = images

      let formData = new FormData()
      formData.append('image', image)
      formData.append('type', image.type)

      setInProgress(true)
      const resp = await axios.post('/api/upload/image', formData, {
        headers: {
          'Content-Type': `multipart/form-data;`,
        },
      })
      setInProgress(false)
      const { height, width, name } = resp.data
      const data = { height, width, name }
      onChange(data)
    } catch (error) {
      console.log(error.response.data)
      setInProgress(false)
      setError(error.response.data)
    }
  }

  return (
    <Suspense fallback={<Loading />}>
      <Drop multiple={false} accept="image/*" onDrop={uploade}>
        {value && value.name && <Img src={`/images/${clubId}/${value.name}`} role="presentation" />}

        {inProgress ? (
          <Height>
            <Loading />
          </Height>
        ) : (
          <Center>
            <MdFileUpload />
            {isNew ? <H2>Drag and drop eller klik for at tilføje billede</H2> : <H2>Drag and drop eller klik for at ændre billedet</H2>}
            <ErrorMsg>{error && error.message}</ErrorMsg>
          </Center>
        )}
      </Drop>
    </Suspense>
  )
}

export default ImageUploader
