import React from 'react'
import { Box } from '@xstyled/styled-components'
import Card from 'components/styles/Card'
import { Title } from 'components/styles/Card'
import { Table, Th, Tr, Td } from './Table'
import calculateAge from 'components/basic/calculateAge'
import Text from 'components/styles/Text'
import { LoadingHigh } from 'components/loading'
import { navigate } from 'hookrouter'
import Button from 'components/styles/Button'

const AdminParticipants = ({ persons = [], customFields = [] }) => {
  const headers = persons.reduce((total, person) => {
    person.customFields.forEach((custom) => {
      if (!total.some((headers) => headers === custom.name)) {
        total.push(custom.name)
      }
    })
    return total
  }, customFields)

  const totalNetPayed = persons.reduce(
    (total, current) => total + +current.net,
    0,
  )
  const totalPayed = persons.reduce(
    (total, current) => total + +current.total,
    0,
  )

  const emailPersons = () => {
    // dispatch(addMultipleRecipients(persons.map((p) => ({ id: p.id, firstName: p.firstName, lastName: p.lastName }))))
    navigate('/admin/mail', false, {
      personIds: persons.map((p) => p.id).join(','),
    })
  }

  const renderRow = (person, index) => {
    const {
      firstName,
      lastName,
      birth,
      customFields = [],
      extraPrices = [],
      total,
      email,
      phoneNumber,
    } = person
    return (
      <Tr key={index}>
        <Td>{index + 1}</Td>
        <Td title={new Date(birth).toLocaleDateString('da-DK')}>
          {calculateAge(person.birth)}
        </Td>
        <Td>{`${firstName} ${lastName}`}</Td>

        {headers.map((c, index) => {
          const val = customFields.find((uc) => uc.name === c)
          return <Td key={index}>{val && val.value}</Td>
        })}

        <Td>{extraPrices.map((e) => `${e.name}: ${e.value}`).join()}</Td>

        <Td>{total}</Td>
        <Td>{email}</Td>
        <Td>{phoneNumber}</Td>
      </Tr>
    )
  }

  if (!persons) return <LoadingHigh />

  return (
    <Card mt>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={['column', 'row']}
      >
        <Box p="2">
          <Title>Alle tilmeldinger</Title>
          <Text color="muted">
            {`I alt ${persons.length} ${persons.length === 1 ? 'tilmeldt' : 'tilmeldte'}, 
             der samlet har betalt netto: ${totalNetPayed.toFixed(2)} gebyr: ${(totalPayed - totalNetPayed).toFixed(2)} total: ${totalPayed.toFixed(
               2,
             )} kroner.`}
          </Text>
        </Box>
        <Box p="2">
          <Button onClick={emailPersons}>Email deltagere</Button>
        </Box>
      </Box>
      {persons.length ? (
        <Table>
          <thead>
            <tr>
              <Th>#</Th>
              <Th>Alder</Th>
              <Th>Navn</Th>
              {headers.map((c, index) => (
                <Th key={index}>{c}</Th>
              ))}
              <Th>Tilvalg</Th>
              <Th>Betalt</Th>
              <Th>E-mail</Th>
              <Th>Telefonnummer</Th>
            </tr>
          </thead>
          <tbody>{persons.map(renderRow)}</tbody>
        </Table>
      ) : (
        <Text color="mutedText" p="3" pt="2" fontSize="2" textAlign="center">
          Aww, der er ingen tilmeldte til denne begivenhed :'(
        </Text>
      )}
    </Card>
  )
}

export default AdminParticipants
