import styled from '@xstyled/styled-components'

const Table = styled.table`
  margin: 2 0;
  box-sizing: border-box;
  font-size: 1.2em;
  border-collapse: collapse;
  width: 100%;
`
const Th = styled.th`
  font-weight: 500;
  /* border-bottom: 1px solid;
  border-color: border; */
  text-align: left;
  padding: 0 2;
  color: mutedText;
  text-transform: uppercase;
`

const Tr = styled.tr`
  height: 2.5em;
  :hover {
    background-color: hover;
  }
`

const Td = styled.td`
  white-space: nowrap;
  padding: 0 2;
`

export { Table, Th, Tr, Td }
