import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

const Loading: FC = () => {
  return (
    <div className="sk-folding-cube">
      <div className="sk-cube1 sk-cube" />
      <div className="sk-cube2 sk-cube" />
      <div className="sk-cube4 sk-cube" />
      <div className="sk-cube3 sk-cube" />
    </div>
  )
}

const Height = styled.div`
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`

export const LoadingHigh: FC = () => (
  <Height>
    <Loading />
  </Height>
)

export const DelayedLoading: FC<{ high: boolean }> = ({ high }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])
  return show ? high ? <LoadingHigh /> : <Loading /> : null
}

export default Loading
