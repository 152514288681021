import { useState, useCallback } from 'react'

import request from 'components/tools/request'

import useUser from './useUser'

const useLogin = () => {
  const [state, setState] = useState({ success: false, inProgress: false, error: null })
  const { setUser } = useUser()

  const login = useCallback(
    async (email, password) => {
      setState({ success: false, inProgress: true, error: null })
      try {
        const resp = await request.post('login', { email, password })
        const user = resp.data
        setUser(user)
        setState({ success: true, inProgress: false, error: null })
      } catch (error) {
        console.log(error.response)
        setState({ success: false, inProgress: false, error: error.response.data })
      }
    },
    [setUser]
  )

  return { login, ...state }
}

export default useLogin
