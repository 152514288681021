import React, { FC, useState } from 'react'
import styled from '@xstyled/styled-components'
import { Field, Form, Formik } from 'formik'

import TextField, { InputProps } from 'components/styles/Input'
import Box from 'components/styles/Box'
import request from 'components/tools/request'
import useUser from 'components/userManagement/useUser'
import Text from 'components/styles/Text'
import { ViewState } from './index'
import Button from 'components/styles/Button'
import ErrorText from 'components/basic/error'

type Props = {
  setView: (key: ViewState) => void
  setInProgress: (value: boolean) => void
  inProgress: boolean
}
const required = (value: string | number) => (value ? undefined : 'Påkrævet')
const passwordRequired = (value: string) => {
  if (!value) return 'Påkrævet'
  if (value.length < 8) return 'Minimum 8 karakterer'
}
const mailRegex =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
let cache = ''
const validateEmail = async (value: string) => {
  if (cache === value) return
  if (!value) return 'Påkrævet'
  if (!mailRegex.test(value)) return 'Ugyldig e-mailadresse'
  const userExist = await request.get<boolean>(`login/userExists/${value}`)
  if (userExist.data)
    return 'Der findes allerede en bruger med den valgte e-mailadresse'
  cache = value
}

const Divider = styled.hr`
  border-top: 1px solid #d0d0d0;
  margin: 3 0;
`

const genderOptions = [
  {
    value: 'male',
    label: 'Mand',
  },
  {
    value: 'female',
    label: 'Kvinde',
  },
]

const MyInput: FC<{ name: string; validate?: any } & InputProps> = ({
  name,
  validate,
  ...props
}) => {
  return (
    <Field name={name} validate={validate || required}>
      {({ field, meta }) => (
        <TextField
          {...field}
          {...props}
          error={meta.touched && meta.error}
          mb="2"
        />
      )}
    </Field>
  )
}

type Profile = {
  email: string
  password: string
  firstName: string
  lastName: string
  gender: 'male' | 'female' | ''
  birth: string
  phoneNumber: string
  zip: string
  street: string
  city: string
}

type Response = {
  id: number
  roles: string[]
  authenticated: boolean
  token: string
}

const CreateUser: React.FC<Props> = ({
  setView,
  setInProgress,
  inProgress,
}) => {
  const { setUser } = useUser()
  const [error, setError] = useState<Error | null>(null)
  const onSubmit = async (data: Profile) => {
    try {
      setInProgress(true)
      const resp = await request.post<Response>('login/create', {
        ...data,
        birth: new Date(data.birth),
      })

      setUser(resp.data)
      setInProgress(false)
      setView('plan')
    } catch (error) {
      setError(error)
      setInProgress(false)
    }
  }

  return (
    <Box p="2">
      <Text fontSize="1.5rem" mb="2">
        Opret Profil
      </Text>
      <Formik
        initialValues={{
          email: '',
          password: '',
          firstName: '',
          lastName: '',
          gender: '',
          birth: '',
          phoneNumber: '',
          zip: '',
          street: '',
          city: '',
        }}
        onSubmit={onSubmit}
      >
        <Form>
          <MyInput
            name="email"
            type="email"
            label="E-mailadresse"
            autoComplete="email"
            validate={validateEmail}
            disabled={inProgress}
          />
          <MyInput
            type="password"
            name="password"
            label="Kodeord"
            autoComplete="current-password"
            validate={passwordRequired}
            disabled={inProgress}
          />
          <Divider />
          <MyInput
            name="firstName"
            label="Fornavn"
            autoComplete="name"
            disabled={inProgress}
          />
          <MyInput
            name="lastName"
            label="Efternavn"
            autoComplete="family-name"
            disabled={inProgress}
          />

          <MyInput
            name="gender"
            label="Køn"
            autoComplete="sex"
            select
            options={genderOptions}
            disabled={inProgress}
          />
          <MyInput
            name="birth"
            type="date"
            label="Fødselsdato"
            autoComplete="birth"
            disabled={inProgress}
            active
          />

          <MyInput
            name="phoneNumber"
            label="Telefonnummer"
            autoComplete="tel"
            disabled={inProgress}
          />
          <MyInput
            name="street"
            label="Gade"
            autoComplete="address-line1"
            disabled={inProgress}
          />
          <MyInput
            type="number"
            label="Postnummer"
            name="zip"
            autoComplete="postal-code"
            disabled={inProgress}
          />
          <MyInput
            label="By"
            name="city"
            autoComplete="address-level1"
            disabled={inProgress}
          />
          {error && <ErrorText>{error.message}</ErrorText>}
          <Box display="flex" justifyContent="flex-end" mb="3">
            <Button as="input" type="submit" round={1} loading={inProgress}>
              Opret
            </Button>
          </Box>
        </Form>
      </Formik>
    </Box>
  )
}

export default CreateUser
