// import React from 'react';
import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
    0% {
      transform:translateY(10px);
        opacity: 0;
      }
      100% {
        transform:translateY(0);
        opacity: 1;
      }
`

export const Card = styled.div`
  background-color: white;
  margin-top: 25px;
  width: calc(50% - 2em);
  animation: ${fadeIn} 0.3s linear;
  box-sizing: border-box;
  border-radius: 6px;

  @media only screen and (max-width: 767px) {
    width: calc(100% - 2em);
  }
`

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
`

export const Title = styled.h1`
  font-size: 2em;
  margin: 0;
  margin-bottom: 0.5em;
  font-weight: normal;
  text-decoration: none;
  color: black;
`

export const CenterText = styled.div`
  text-align: center;
  margin-bottom: 3em;
  margin-top: 3em;
  color: rgba(0, 0, 0, 0.68);
`

export const Subtitle = styled.sub`
  color: #757575;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 3.2rem;
  line-height: 1.25;
  margin: 0;
`

export const Text = styled.p``
export const AdminText = styled.p`
  color: #2185d0;
  > * {
    color: #2185d0;
  }
`

export const Img = styled.img`
  display: block;
  width: 100%;
  height: auto;
`

export const Icons = styled.div`
  float: right;
  font-size: 1.4em;
  margin-right: 16px;
  margin-top: 6px;
  cursor: pointer;

  svg {
    color: #757575;
  }
`

export const CenterCardText = styled.div`
  text-align: center;
  margin-bottom: 1em;
  margin-top: 1em;
  color: rgba(0, 0, 0, 0.68);
`

export const FullCard = styled.div`
  background-color: white;
  margin: 16px 1em;
  box-sizing: border-box;
  width: calc(100% - 2em);
  animation: ${fadeIn} 0.3s linear;
  border-radius: 6px;
`

export const SmallCard = styled.div`
  background-color: white;
  margin: 20px 16px;
  animation: ${fadeIn} 0.3s linear;
  box-sizing: border-box;
  @media only screen and (max-width: 767px) {
    width: calc(100% - 2em);
    border-radius: 6px;
  }
`

export const ScrollCard = styled.div`
  @media only screen and (min-width: 768px) {
    margin: 0 1em;
  }

  background-color: white;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-right: -8px;
  margin-left: -8px;
  display: block;
  overflow-x: auto;
  box-sizing: border-box;
  margin: 0 0 0 1em;
  border-radius: 6px;
`

export const Margin = styled.div`
  padding: 2em;
`

export default Card
