import React, { FC } from 'react'
import Info from './Info'
import ErrorText from 'components/basic/error'
import roles from 'roles'
import useUser from 'components/userManagement/useUser'
import { DelayedLoading } from 'components/loading'
import Text from 'components/styles/Text'
import useSWR from 'swr'
import AdminSubscriptions from '../AdminSubscriptions'
import MySubscriptions from '../MySubscriptions'

type Plan = {
  id: number
  slug: string
  name: string
  shortDescription: string
  description: string
  price: string
  interval: number
  clubId: string
  club_id: number
  images: unknown[]
  paymentDates: string[]
  nextBilling: string
}
const DetailPage: FC<{ slug: string }> = ({ slug }) => {
  const { data, error } = useSWR<Plan>(`plans/${slug}`)
  const { user } = useUser()
  const { authenticated, roles: userRoles } = user

  if (error) return <ErrorText>{error.message}</ErrorText>
  if (!data) return <DelayedLoading high />

  return (
    <>
      <Info
        authenticated={user.authenticated}
        plan={data}
        canEdit={userRoles.includes(roles.teamManager)}
      />
      {authenticated && (
        <>
          <Text mt="3" mb="0" ml="2" fontSize="5">
            Tilmeldinger
          </Text>
          <MySubscriptions currentPlanId={data.id} />
        </>
      )}

      {(userRoles.includes(roles.coach) ||
        userRoles.includes(roles.cashier)) && (
        <AdminSubscriptions planId={data.id} />
      )}
    </>
  )
}

export default DetailPage
