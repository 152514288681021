import styled from 'styled-components'


const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: ${props => (props.spaceBetween ? 'space-between' : 'flex-start')};
  flex-wrap: ${props => props.wrapContent ? 'wrap' : 'unset'};
  margin-top: ${props => props.marginTop};
`

export default Row