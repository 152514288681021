import { Box } from '@xstyled/styled-components'
import { LoadingHigh } from 'components/loading'
import Button from 'components/styles/Button'
import { CardGrid } from 'components/styles/Card'
import Text from 'components/styles/Text'
import useUser from 'components/userManagement/useUser'
import React, { useState } from 'react'
import roles from 'roles'
import useSWR from 'swr'
import useSWRInfinite from 'swr/infinite'

import CreateUpdateEvent from '../CreateUpdate'
import InfoBox from './InfoBox'
import OverviewCard from './OverviewCard'

export const URL_KEY = 'events'
const URL_KEY_ALL = URL_KEY + '/all'
const LIMIT = 10

const Overview = () => {
  const [isOpen, setIsOpen] = useState(false)

  const { data: currentEvents } = useSWR(URL_KEY)
  const { user } = useUser()
  const [fetchMore, setFetchMore] = useState(false)

  const {
    data: archiveEvents,
    error: archiveError,
    size,
    setSize,
    isValidating,
  } = useSWRInfinite((index, previousPageData) => {
    if (fetchMore === false) return null
    if (previousPageData && previousPageData.length < LIMIT) return null

    return `${URL_KEY_ALL}?offset=${index * LIMIT}&limit=${LIMIT}`
  })
  const isReachingEnd =
    archiveEvents && archiveEvents[archiveEvents.length - 1]?.length < LIMIT

  const handleLoadMore = () => {
    if (!fetchMore) {
      setFetchMore(true)
      return
    }
    setSize(size + 1)
  }

  const renderArchive = () => {
    return archiveEvents?.map((events) =>
      events.map((e) => <OverviewCard key={e.id} event={e} />),
    )
  }

  if (!currentEvents) return <LoadingHigh />
  return (
    <>
      {user.roles.includes(roles.eventManger) && (
        <Box mt="2">
          <Button onClick={() => setIsOpen(true)}>Opret Begivenhed</Button>
        </Box>
      )}
      <InfoBox />
      <CardGrid>
        {currentEvents.events.map((e) => (
          <OverviewCard key={e.id} event={e} />
        ))}
        {/* {pages} */}
        {renderArchive()}
      </CardGrid>
      <Box mt="4">
        {archiveError && (
          <Text textAlign="center" my="3">
            {archiveError.message}
          </Text>
        )}
        {/* <Button round loading={more && !error && isLoadingMore} disabled={isReachingEnd || error} onClick={() => setSize(size + 1)} center> */}
        <Button
          round
          loading={isValidating}
          disabled={isReachingEnd}
          onClick={handleLoadMore}
          center
        >
          Se tidligere begivenheder
        </Button>
      </Box>
      {isOpen && (
        <CreateUpdateEvent
          onClose={() => setIsOpen(false)}
          isEditing={false}
          slug={null}
        />
      )}
    </>
  )
}

export default Overview
