import create from 'zustand'
import axios from 'axios'

const URL = '/api/admin/failedCharges'

const mapper = v => ({ data: v.data, timestamp: new Date(v.timestamp) })

const [useInsights] = create((set, get) => ({
  store: {
    data: [],
    timestamp: null
  },
  error: null,
  fetching: false,
  success: false,
  initialSuccess: false,

  actions: {
    fetch: async () => {
      if (!get().initialSuccess) {
        try {
          set(() => ({ fetching: true, success: false, error: null }))
          const resp = await axios.get(URL)
          console.log(resp.data)

          const store = mapper(resp.data)
          set(() => ({ store, initialSuccess: true, success: true, fetching: false }))
        } catch (error) {
          console.log(error)
          set(() => ({ error: error.response.data, fetching: false, success: false }))
        }
      }
    }
  }
}))

export default useInsights
