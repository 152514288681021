import { Confirm } from 'components/basic/Modal'
import request from 'components/tools/request'
import useUser from 'components/userManagement/useUser'
import React, { useState } from 'react'
import { mutate as globalMutate } from 'swr'
import { useSubscriptionPersons } from './useSubscriptionPersons'

type Props = {
  subscriptionUid: string
  personName: string
  planName: string
  onClose: () => void
}

const ConfirmUnsubscribe: React.FC<Props> = ({
  onClose,
  personName,
  planName,
  subscriptionUid,
}) => {
  const { mutate } = useSubscriptionPersons()
  const { user } = useUser()

  const [inProgress, setInProgress] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const handleUnsubscribe = async () => {
    try {
      setError(null)
      setInProgress(true)

      await request.post('subscription-v2/cancel', { subscriptionUid })
      mutate((s) =>
        (s || []).map((person) => ({
          ...person,
          subscriptions: person.subscriptions.map((s) =>
            s.uid === subscriptionUid
              ? {
                  ...s,
                  status: 'canceled',
                  cancellationDate: new Date().toJSON(),
                }
              : s,
          ),
        })),
      )

      if (user.roles.includes('cashier')) {
        await globalMutate('subscription-v2/all')
      }

      setInProgress(false)
      onClose()
    } catch (error) {
      setInProgress(false)
      setError(error)
    }
  }

  return (
    <Confirm
      isOpen
      danger
      inProgress={inProgress}
      onConfirm={handleUnsubscribe}
      onClose={onClose}
      error={error}
      text={`Er du sikke på du vil udmelde ${personName} fra ${planName}?`}
      primaryText="Udmeld"
    />
  )
}

export default ConfirmUnsubscribe
