import React from 'react'
import { Box } from '@xstyled/styled-components'

import Card from 'components/styles/Card'
import { Title } from 'components/styles/Card'
import { Table } from './Table'
import { Th } from './Table'
import { Tr } from './Table'
import { Td } from './Table'
import Text from 'components/styles/Text'
import { LoadingHigh } from 'components/loading'

const MyParticipants = ({ persons = [], customFields: headers = [] }) => {
  const renderRow = (person, index) => {
    const { firstName, lastName, customFields = [], extraPrices = [], total } = person
    return (
      <Tr key={index}>
        <Td>{`${firstName} ${lastName}`}</Td>

        {headers.map((c, index) => {
          const val = customFields.find(uc => uc.name === c)
          return <Td key={index}>{val && val.value}</Td>
        })}

        <Td>{extraPrices.map(e => `${e.name}: ${e.value}`).join()}</Td>
        <Td>{total}</Td>
      </Tr>
    )
  }

  if (!persons) return <LoadingHigh />

  return (
    <Card mt>
      <Box p="2">
        <Title>Mine tilmeldinger</Title>
      </Box>
      {persons.length ? (
        <Table>
          <thead>
            <tr>
              <Th>Navn</Th>
              {headers.map((c, index) => (
                <Th key={index}>{c}</Th>
              ))}
              <Th>Tilvalg</Th>
              <Th>Betalt</Th>
            </tr>
          </thead>
          <tbody>{persons.map(renderRow)}</tbody>
        </Table>
      ) : (
        <Text color="mutedText" p="3" pt="2" fontSize="2" textAlign="center">
          Hov! Du har slet ikke tilmeldt nogen til denne begivenhed.
        </Text>
      )}
    </Card>
  )
}

export default MyParticipants
