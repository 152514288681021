import React from 'react'
import styled from 'styled-components'

const CalBox = styled.a`
  width: 36px;
  border-right: 0.5px #000000 solid;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 10px;
`
const Month = styled.div`
  color: red;
  margin-top: 8px;
`
const Day = styled.div`
  font-size: 2em;
  color: black;
`

const CalendarIcon = props => {
  const date = new Date(props.children)
  const shortMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Maj',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dec'
  ]
  
  return (
    <CalBox href={props.link} target="_blank" rel="noopener noreferrer">
      <Month>{shortMonths[date.getMonth()]}</Month>
      <Day>{date.getDate()}</Day>
    </CalBox>
  )
}

export default CalendarIcon
