import React, { Suspense } from 'react'
import Modal from 'components/basic/Modal/Modal'
import { LoadingHigh } from 'components/loading'
const CreateUpdate = React.lazy(() => import('./CreateUpdate'))

const CreateUpdateModal = ({ slug, onClose, isEditing }) => {
  return (
    <Modal wide isOpen toggle={onClose} closeOnBackdropClick={false}>
      <Suspense fallback={<LoadingHigh />}>
        <CreateUpdate slug={slug} onClose={onClose} isEditing={isEditing} />
      </Suspense>
    </Modal>
  )
}

export default CreateUpdateModal
