import React, { useState, useEffect } from 'react'

import styled from 'styled-components'
import Input from './../../styles/Input'

import { ErrorText } from './../../basic/error.jsx'
import Button from './../../styles/Button'
import Box from 'components/styles/Box'
import ResetPassword from './ResetPassword'
import Row from 'components/styles/Row'
import { Title } from 'components/styles/Card'
import useUser, { useLogin } from 'components/userManagement/useUser'
import Text from 'components/styles/Text'
import useAuthModals from '../Register/useAuthModals'
import Modal from 'components/basic/Modal/Modal'

const Link = styled.span`
  color: ${(p) => p.theme.colors.link};
  cursor: pointer;
`

const Login = () => {
  const { setShowLoginModal, showLoginModal, setShowRegisterModal } = useAuthModals((p) => ({
    setShowLoginModal: p.setShowLogin,
    showLoginModal: p.showLogin,
    setShowRegisterModal: p.setShowRegister,
  }))

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showResetPassword, setShowResetPassword] = useState(false)
  const { user } = useUser()
  const { login, inProgress, error } = useLogin()

  useEffect(() => {
    if (user.authenticated) {
      setShowLoginModal(false)
    }
  }, [setShowLoginModal, user, user.authenticated])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin()
    }
  }

  const handleLogin = () => {
    login(email, password)
  }

  const handleGoToRegister = () => {
    setShowLoginModal(false)
    setShowRegisterModal(true)
  }

  const renderLoginForm = () => {
    return (
      <Box maxWidth="smallModal" mx="normal" mb="normal">
        <Box mb="normal" mt={['normal', 0]}>
          <Title>Login</Title>
          <Text>
            Eller <Link onClick={handleGoToRegister}>opret dig som bruger.</Link>
          </Text>
        </Box>

        <Input type="email" label="Email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} onKeyPress={handleKeyPress} />
        <Input
          my="2"
          type="password"
          label="Password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={handleKeyPress}
        />

        <ErrorText>{error && error.message}</ErrorText>

        <Row spaceBetween>
          <Button onClick={() => setShowResetPassword(true)} text>
            Glemt kodeord?
          </Button>
          <Button round onClick={handleLogin} loading={inProgress}>
            Login
          </Button>
        </Row>
      </Box>
    )
  }

  return (
    <Modal isOpen={showLoginModal} toggle={() => setShowLoginModal(false)}>
      {showLoginModal && (showResetPassword ? <ResetPassword email={email} setEmail={setEmail} back={() => setShowResetPassword(false)} /> : renderLoginForm())}{' '}
    </Modal>
  )
}

export default Login
