import ErrorText from 'components/basic/error'
import Modal from 'components/basic/Modal'
import { LoadingHigh } from 'components/loading'
import Box from 'components/styles/Box'
import Button from 'components/styles/Button'
import Card2 from 'components/styles/Card2'
import Text from 'components/styles/Text'
import request from 'components/tools/request'
import useUser from 'components/userManagement/useUser'
import React, { useState } from 'react'
import useSWR, { mutate as globalMutate } from 'swr'
import { Plan } from 'types'
import { useSubscriptionPersons } from './useSubscriptionPersons'

type Props = {
  currentPlanId: number
  subscriptionUid: string
  personName: string
  onClose: () => void
}

const ChangeSubscription: React.FC<Props> = ({
  onClose,
  currentPlanId,
  subscriptionUid,
}) => {
  const { mutate } = useSubscriptionPersons()
  const { data } = useSWR<Plan[]>('plans')
  const { user } = useUser()

  const [selectedPlan, setSelectedPlan] = useState<number>(currentPlanId)

  const [inProgress, setInProgress] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const handleChange = async () => {
    try {
      setError(null)
      setInProgress(true)

      await request.post('subscription-v2/move', {
        newPlanId: selectedPlan,
        subscriptionUid,
      })

      const newPlan = data?.find((p) => p.id === selectedPlan)

      if (!newPlan) {
        setError(new Error('Noget gik galt'))
        setInProgress(false)
        return
      }

      mutate((s) =>
        (s || []).map((person) => ({
          ...person,
          subscriptions: person.subscriptions.map((s) =>
            s.uid === subscriptionUid
              ? {
                  ...s,
                  planId: selectedPlan,
                  planName: newPlan.name,
                  slug: newPlan.slug,
                }
              : s,
          ),
        })),
      )

      if (user.roles.includes('cashier')) {
        await globalMutate('subscription-v2/all')
      }

      setInProgress(false)
      onClose()
    } catch (error) {
      setInProgress(false)
      setError(error.response.data)
    }
  }

  const renderPlan = (plan: Plan) => {
    const { name, id, shortDescription } = plan

    return (
      <Box
        m="2"
        key={id}
        onClick={() => !inProgress && setSelectedPlan(id)}
        disabled={inProgress}
      >
        <Card2 p="2" borderColor={selectedPlan === id ? 'primary' : 'border'}>
          <Text fontSize="1.5rem">{name}</Text>
          <Text>{shortDescription}</Text>
        </Card2>
      </Box>
    )
  }

  if (!data)
    return (
      <Modal isOpen toggle={onClose}>
        <LoadingHigh />
      </Modal>
    )

  return (
    <Modal isOpen toggle={onClose} persistent={inProgress}>
      <Box pb="3">
        <Text m="2" fontSize="1.6rem">
          Vælg det hold du gerne vi skifte dig
        </Text>

        {data.map(renderPlan)}

        <Button
          center
          round
          onClick={handleChange}
          disabled={selectedPlan === currentPlanId}
          loading={inProgress}
        >
          Skift hold
        </Button>
        {error && <ErrorText>{error.message}</ErrorText>}
      </Box>
    </Modal>
  )
}

export default ChangeSubscription
