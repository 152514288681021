import styled from "@xstyled/styled-components";
import React from "react";
import { MdClose } from "react-icons/md";
import { Portal } from "react-portal";

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 201;
`;

const ModalStyle = styled.div`
  z-index: 202;
  background: #fff;
  min-width: 500px;
  width: ${(p) => (p.wide ? "1019px" : "automatic")};
  max-width: 1019px;
  overflow-y: auto;
  max-height: calc(100vh - 110px);
  border-radius: 6px;
  @media only screen and (max-width: 767px) {
    ${(p) =>
			p.dialog
				? `
            min-width: 0;
            width: 90vw;
          `
				: `
            position: absolute;
            background: #fff;
            top: 0;
            left: 0px;
            min-width: 0;
            width: 100%;
            height: 100vh;
            max-height: 100vh;
            border-radius: 0;
          `}
  }
`;

const BackdropStyle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 199;
  background: rgba(0, 0, 0, 0.3);
`;
const Header = styled.div`
  color: black;
  height: 50px;

  @media only screen and (max-width: 767px) {
    position: fixed;
    width: 100%;
    background: ${(p) => (p.dialog ? "transparent" : p.theme.colors.menu)};
    z-index: 201;
    color: ${(p) => (p.dialog ? "black" : "#fff")};
  }
`;

const Content = styled.div`
  @media only screen and (max-width: 767px) {
    padding-top: 50px;
  }
`;

const Icon = styled.i`
  margin-left: 5px;
  font-size: 33px;
  opacity: 0.9;
  cursor: pointer;
`;

export const Modal = ({
	isOpen,
	toggle,
	persistent = false,
	closeOnBackdropClick = true,
	children,
	dialog = false,
	wide = false,
}) => {
	const close = (e) => {
		if (!persistent) toggle();
	};

	const handleBackdropClick = (e) => {
		if (closeOnBackdropClick) close();
	};

	if (isOpen === false) {
		return null;
	}

	return (
		<Portal>
			<Container>
				<BackdropStyle onClick={handleBackdropClick} />
				<ModalStyle dialog={dialog} wide={wide}>
					<div>
						<Header dialog={dialog}>
							{!persistent && (
								<Icon onClick={close}>
									<MdClose />
								</Icon>
							)}
						</Header>
					</div>
					<Content>{children}</Content>
				</ModalStyle>
			</Container>
		</Portal>
	);
};

export default Modal;
