import React from 'react'
import styled from 'styled-components'


export const ErrorText = ({ children, width = 'none', marginBottom = 'none', left = false }) => {
  const ErrorStyle = styled.p`
    text-align: ${left ? 'left' : 'center'};
    color: red;
    margin-top: 2em;
    padding-bottom: ${marginBottom};
    font-style: italic;
    max-width: ${width === 'none' ? 'none' : width + 'px'};
  `
  return children ? <ErrorStyle>{children}</ErrorStyle> : null
}

export const ErrorMessage = ({ message }) => {
  const ErrorStyle = styled.p`
    color: red;
    margin-top: 2em;
    font-style: italic;
  `
  return message ? <ErrorStyle>Der skete en fejl, prøv igen ({message})</ErrorStyle> : null
}

export default ErrorText