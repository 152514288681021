import React from 'react'

const months = [
  'januar',
  'februar',
  'marts',
  'april',
  'maj',
  'juni',
  'juli',
  'august',
  'september',
  'oktober',
  'november',
  'december'
]

export const formatDate = date => {
  const _date = new Date(date)
  return (
    _date.getDate() +
    '. ' +
    months[_date.getMonth()] +
    ' ' +
    _date.getFullYear()
  )
}

export const NiceDate = ({ date }) => {
  return <span>{formatDate(date)}</span>
}

const shortDate = date => {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}

const isSameDate = (date1, date2) => {
  const _date1 = new Date(date1)
  const _date2 = new Date(date2)

  return (
    _date1.getFullYear() === _date2.getFullYear() &&
    _date1.getMonth() === _date2.getMonth() &&
    _date1.getDate() === _date2.getDate()
  )
}

const isSameMonth = (date1, date2) => {
  const _date1 = new Date(date1)
  const _date2 = new Date(date2)

  return (
    _date1.getFullYear() === _date2.getFullYear() &&
    _date1.getMonth() === _date2.getMonth()
  )
}

export const niceDates = (date1, date2) => {
  const _date1 = new Date(date1)
  const _date2 = new Date(date2)

  if (isSameDate(_date1, _date2)) {
    return shortDate(_date1)
  } else {
    return shortDate(_date1) + ' - ' + shortDate(_date2)
  }
}

export const longDates = (date1, date2) => {
  const _date1 = new Date(date1)
  const _date2 = new Date(date2)
  if (isSameDate(date1, date2)) {
    return formatDate(date1)
  } else if (isSameMonth(date1, date2)) {
    return `${_date1.getDate()} - ${_date2.getDate()}. ${
      months[_date1.getMonth()]
    } ${_date2.getFullYear()}`
  } else {
    return formatDate(date1) + ' - ' + formatDate(date2)
  }
}

export const NiceDatesAndTime = ({ dateOne, dateTwo }) => {
  const niceDate = dateObj => {
    return (
      dateObj.getDate() +
      '. ' +
      months[dateObj.getMonth()] +
      ' ' +
      dateObj.getFullYear()
    )
  }

  const niceTime = dateObj => {
    return dateObj.toLocaleTimeString('da').slice(0, 5)
  }

  const formatDates = () => {
    const _dateOne = new Date(dateOne)
    const _dateTwo = new Date(dateTwo)

    if (
      _dateOne.getFullYear() === _dateTwo.getFullYear() &&
      _dateOne.getMonth() === _dateTwo.getMonth() &&
      _dateOne.getDate() === _dateTwo.getDate()
    ) {
      return (
        niceDate(_dateOne) +
        ', ' +
        niceTime(_dateOne) +
        ' - ' +
        niceTime(_dateTwo)
      )
    } else {
      return (
        niceDate(_dateOne) +
        ', ' +
        niceTime(_dateOne) +
        ' - ' +
        niceDate(_dateTwo) +
        ', ' +
        niceTime(_dateTwo)
      )
    }
  }

  return <span>{formatDates()}</span>
}
