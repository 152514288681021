import React, { FC, Suspense, useState } from 'react'
import Modal from '../../basic/Modal/Modal'
import { LoadingHigh } from 'components/loading'
import Button from 'components/styles/Button'
import {
  useSubscriptionPersons,
  SubscriptionPersonsList,
} from '../useSubscriptionPersons'
import Box from 'components/styles/Box'
import Text from 'components/styles/Text'
import { colors } from 'components/styles/styles'
import CreatePerson from 'components/Persons/CreatePersonModal'

const SubscribePayment = React.lazy(() => import('./SubscribePayment'))

type Props = {
  onClose: (oneMore?: boolean) => void
  show: boolean
  showOneMore?: boolean
  planId: number
}

const SubscribeModalV2: FC<Props> = ({
  onClose,
  show,
  planId,
  showOneMore,
}) => {
  const { data, error, mutate } = useSubscriptionPersons()
  const [showPaymentModal, setShowPaymentModal] = useState(false)

  const [personId, setPersonId] = useState<number | null>(null)
  const [showCreatePerson, setShowCreatePerson] = useState(false)

  const renderPerson = (person: SubscriptionPersonsList) => {
    const { firstName, lastName, id, subscriptions } = person
    const isSignedUp = subscriptions.some((s) => s.planId === planId)
    return (
      <Box
        m="2"
        p="2"
        style={{ cursor: isSignedUp ? 'not-allowed' : 'pointer' }}
        border={`1px solid ${personId === id ? colors.primary : colors.border}`}
        borderRadius="8px"
        bg={isSignedUp ? '#e4e4e4' : personId === id ? '#afceec' : 'white'}
        transition="all 150ms ease-in"
        boxShadow={
          isSignedUp ? undefined : 'rgba(149, 157, 165, 0.2) 0px 4px 12px'
        }
        color={isSignedUp ? '#afafaf' : undefined}
        key={id}
        onClick={isSignedUp ? undefined : () => setPersonId(id)}
      >
        <Text fontSize="1.2rem">
          {firstName} {lastName}
        </Text>
        {isSignedUp && <Text color="#787676">Er tilmeldt</Text>}
      </Box>
    )
  }
  return (
    <Modal isOpen={show} toggle={() => onClose()}>
      <Suspense fallback={<LoadingHigh />}>
        {error && (
          <Text fontSize="1.2rem" mt="6" textAlign="center">
            {error.message}
          </Text>
        )}

        {!showPaymentModal && (
          <>
            <Text m="2" fontSize="1.5rem">
              Vælg hvem der skal tilmeldes
            </Text>
            {data?.map(renderPerson)}
            <Box m="2" mt="4">
              <Button text fullWidth onClick={() => setShowCreatePerson(true)}>
                Opret ny person
              </Button>
            </Box>
            <Box m="2" mt="4">
              <Button
                disabled={personId === null}
                fullWidth
                onClick={() => setShowPaymentModal(true)}
              >
                Tilmeld og Betal
              </Button>
            </Box>
          </>
        )}

        {showCreatePerson && (
          <CreatePerson
            setPerson={(personId) => {
              mutate()
              setPersonId(Number(personId))
            }}
            onClose={() => setShowCreatePerson(false)}
          />
        )}
        {showPaymentModal && personId && (
          <SubscribePayment
            showOneMore={showOneMore}
            planId={planId}
            onClose={onClose}
            personId={personId}
          />
        )}
      </Suspense>
    </Modal>
  )
}

export default SubscribeModalV2
