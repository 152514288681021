import styled from 'styled-components'
import { PAGE_MAX_WIDTH } from '../../styles';

export const Container = styled.div `
    display: flex;
    justify-content: center;
`

export const Page = styled.div `
    max-width: ${PAGE_MAX_WIDTH};
    min-width: 118px;
    width: 100%;
    margin-bottom: 58px;
    margin-top: 50px; 

    
    @media only screen and (min-width: 767px){  
        min-height: calc(100vh - 94px);
        margin-top: 61px;  
        margin-bottom: 0;  
    }

`