const dateTimeFormat = new Intl.DateTimeFormat('da', {
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}).format
const dateTimeYearFormat = new Intl.DateTimeFormat('da', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}).format

const dateFormat = new Intl.DateTimeFormat('da', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}).format
const timeFormat = new Intl.DateTimeFormat('da', {
  hour: 'numeric',
  minute: 'numeric',
}).format

const today = new Date()

function isSameDay(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()
}

const formatDateRange = (from, to) => {
  if (isSameDay(from, to)) return `${dateFormat(from)} ${timeFormat(from)} - ${timeFormat(to)}`

  const formattedFrom = from.getFullYear() === today.getFullYear() ? dateTimeFormat(from) : dateTimeYearFormat(from)
  const formattedTo = to.getFullYear() === today.getFullYear() ? dateTimeFormat(to) : dateTimeYearFormat(to)

  return `${formattedFrom} - ${formattedTo}`
}

export default formatDateRange
