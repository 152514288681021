import Text from 'components/styles/Text'
import React, { useMemo, useState } from 'react'
import Card2 from 'components/styles/Card2'
import { A } from 'hookrouter'
import Box from 'components/styles/Box'
import Button from 'components/styles/Button'
import SubscribePaymentModal from 'components/plans/SubscribeModalV2/SubscribePaymentModal'
import ConfirmUnsubscribe from './ConfirmUnsubscribe'
import {
  useSubscriptionPersons,
  PersonSubscription,
  SubscriptionPersonsList,
} from './useSubscriptionPersons'
import ChangeSubscription from './ChangeSubscription'

type Props = { currentPlanId?: number }

type Subscribe = {
  personName: string
  personId: number
  planName: string
  planSlug: string
  planId: number
}
type Unsubscribe = {
  planName: string
  personName: string
  subscriptionUid: string
}
type Change = {
  currentPlanId: number
  subscriptionUid: string
  personName: string
}

const MySubscriptions: React.FC<Props> = ({ currentPlanId }) => {
  const [legacyModal, setLegacyModal] = useState<Subscribe | null>(null)
  const [showUnsubscribe, setShowUnsubscribe] = useState<null | Unsubscribe>(
    null,
  )
  const [showChange, setShowChange] = useState<null | Change>(null)
  const { data = [] } = useSubscriptionPersons()

  const dataToShow = useMemo(
    () =>
      currentPlanId !== undefined
        ? data.filter(({ subscriptions }) =>
            subscriptions.some((s) => s.planId === currentPlanId),
          )
        : data,
    [data, currentPlanId],
  )

  const renderSub = ({
    adminSignUp,
    cancellationDate,
    currentPeriodEnd,
    personId,
    planId,
    planName,
    slug,
    status,
    uid,
    personName,
  }: PersonSubscription & { personName: string }) => {
    return (
      <Card2 key={uid} m="2" p="2">
        <Text fontSize="4">
          <A href={`/teams/${slug}`}>{planName}</A>
        </Text>
        {status === 'legacy' && (
          <>
            <Text>Næste betaling vil ikke ske automatisk</Text>
            <Box mt="2" display="flex" justifyContent="space-around">
              <Button
                onClick={() => {
                  setLegacyModal({
                    personId,
                    planId,
                    planSlug: slug,
                    personName,
                    planName,
                  })
                }}
              >
                Opdater Betaling
              </Button>
            </Box>
          </>
        )}
        {status === 'canceled' && cancellationDate && (
          <Text>
            Udmeldt, den{' '}
            {new Date(cancellationDate).toLocaleDateString('da-DK')} der vil
            ikke ske flere automatiske betalinger
          </Text>
        )}
        {status === 'active' && adminSignUp && (
          <Text>
            Aktiv indtil:{' '}
            {new Date(currentPeriodEnd).toLocaleDateString('da-DK')}
          </Text>
        )}
        {status === 'active' && !adminSignUp && (
          <>
            <Text>
              Næste betaling:{' '}
              {new Date(currentPeriodEnd).toLocaleDateString('da-DK')}
            </Text>
            <Box mt="2" display="flex" justifyContent="space-around">
              <Button
                outline
                warning
                onClick={() =>
                  setShowChange({
                    personName,
                    subscriptionUid: uid,
                    currentPlanId: planId,
                  })
                }
              >
                Flyt hold
              </Button>
              <Button
                outline
                danger
                onClick={() =>
                  setShowUnsubscribe({
                    subscriptionUid: uid,
                    personName,
                    planName,
                  })
                }
              >
                Udmeld
              </Button>
            </Box>
          </>
        )}
      </Card2>
    )
  }

  const renderStuff = ({
    firstName,
    id,
    lastName,
    subscriptions,
  }: SubscriptionPersonsList) => {
    const personName = `${firstName} ${lastName}`
    return (
      <Box m="2" key={id}>
        <Text fontSize="4">{personName}</Text>

        {subscriptions.length ? (
          subscriptions.map((s) => renderSub({ ...s, personName }))
        ) : (
          <Text
            color="muted"
            width="100%"
            mt="4"
            fontStyle="italic"
            textAlign="center"
          >{`Ingen holdtilmeldinger :'(`}</Text>
        )}
      </Box>
    )
  }

  return (
    <div>
      {dataToShow.map(renderStuff)}
      {legacyModal && (
        <SubscribePaymentModal
          {...legacyModal}
          onClose={() => setLegacyModal(null)}
        />
      )}
      {showUnsubscribe && (
        <ConfirmUnsubscribe
          {...showUnsubscribe}
          onClose={() => setShowUnsubscribe(null)}
        />
      )}
      {showChange && (
        <ChangeSubscription
          {...showChange}
          onClose={() => setShowChange(null)}
        />
      )}
    </div>
  )
}

export default MySubscriptions
