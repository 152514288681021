import React from 'react'
import ImageCard from './ImageCard'
import { subtitleCreator } from './utils'
import useSWR from 'swr'
import { ErrorText } from 'components/basic/error'

const ArticlePage = ({ slug }) => {
  const { data, error } = useSWR(`news/${slug}`)

  if (error) return <ErrorText>{error.message}</ErrorText>
  if (!data) return <ImageCard />

  const {
    id,
    headline,
    text,
    firstName,
    lastName,
    publishDate,
    image,
    picture,
  } = data
  const subtitle = subtitleCreator(firstName, lastName, new Date(publishDate))

  return (
    <ImageCard
      key={id}
      title={headline}
      subtitle={subtitle}
      url={slug}
      text={text}
      image={image || picture}
    />
  )
}

export default ArticlePage
