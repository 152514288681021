import React, { useState, useEffect } from 'react'
import Modal from 'components/basic/Modal/Modal'
import request from 'components/tools/request'
import Button from 'components/styles/Button'
import Text from 'components/styles/Text'
import useSWR, { mutate } from 'swr'
import { Box } from '@xstyled/styled-components'

import Input from 'components/styles/Input'

const EditAttendee = ({ onClose, user, dataKey }) => {
  const { data, mutate: userMutate } = useSWR(`admin/attendee/${user.id}`)
  const [name, setName] = useState(user.name)
  const [approved, setApproved] = useState(user.approved)

  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (data) {
      setName(data.name)
      setApproved(data.approved)
    }
  }, [data])

  const handleUpdate = async () => {
    try {
      setInProgress(true)
      const resp = await request.put(`admin/attendee/${user.id}`, { ...user, approved, name })
      //Update store
      userMutate(resp.data)
      mutate(dataKey, (data) => data.map((u) => (u.id === user.id ? { ...u, firstName: name, approved } : u)))

      setInProgress(false)
      onClose()
    } catch (error) {
      console.log(error)

      setError(error)
      setInProgress(false)
    }
  }

  // if (!data) return <div></div>
  // const { createdAt, count } = data
  const { createdAt } = data || {}

  return (
    <Modal isOpen={true} toggle={onClose} persistent={inProgress}>
      <Box p="3">
        <Input mb="2" label="Navn" value={name} onChange={(e) => setName(e.target.value)} />
        <Text mt="2">Oprettet den: {new Date(createdAt).toLocaleDateString()}</Text>
        <Box mt="2">
          <input id="approved" type="checkbox" onChange={(e) => setApproved(e.target.checked)} checked={approved} />
          <label htmlFor="approved">Går på et andet hold</label>
        </Box>
        {/* <div>Antal træninger: {count}</div> */}
        <Text my="3" textAlign="center" color="danger">
          {error && error.message}
        </Text>
        <Button right loading={inProgress} onClick={handleUpdate}>
          Gem
        </Button>
      </Box>
    </Modal>
  )
}

export default EditAttendee
