import { Box } from '@xstyled/styled-components'
import LinkCapture from 'components/basic/linkCapture'
import Loading from 'components/loading'
import Card from 'components/styles/Card'
import Text from 'components/styles/Text'
import useUser from 'components/userManagement/useUser'
import React, { Suspense, useState } from 'react'
import { MdEdit } from 'react-icons/md'
import roles from 'roles'
import useSWR from 'swr'

import EditFrontPage from './Edit'

const Carousel = React.lazy(() => import('../Carousel'))

const FrontPage = () => {
  const { user } = useUser()
  const { data, error } = useSWR('infoCard/frontpage')

  const [editMode, setEditMode] = useState(false)

  if (error)
    return (
      <Text mt="4" textAlign="center">
        {error.message}
      </Text>
    )
  if (!data) return <Loading />

  if (editMode) return <EditFrontPage onClose={() => setEditMode(false)} />
  return (
    <>
      <Card mt>
        <Suspense fallback={<Loading />}>
          <Carousel images={data.images} />
        </Suspense>

        {user.roles.includes(roles.editor) && (
          <Box display="flex" justifyContent="flex-end" p="1">
            <MdEdit onClick={() => setEditMode(true)} />
          </Box>
        )}
        <Box m="2">
          <LinkCapture>
            <div dangerouslySetInnerHTML={{ __html: data.body }} />
          </LinkCapture>
        </Box>
      </Card>
    </>
  )
}

export default FrontPage
