import React from 'react'
// import  from './Box'
import { colors } from './styles'
import styled, { Box, keyframes } from '@xstyled/styled-components'

type Props = {}

const fadeIn = keyframes`
    0% {
      transform:translateY(10px);
        opacity: 0;
      }
      100% {
        transform:translateY(0);
        opacity: 1;
      }
`
const Animation = styled.div`
  animation: ${fadeIn} 0.3s linear;
`

const Card2: React.FC<Partial<BoxProps>> = ({ children, ...props }) => {
  return (
    <Animation>
      <Box borderRadius="8px" border={`1px solid ${colors.border}`} bg="white" {...props}>
        {children}
      </Box>
    </Animation>
  )
}

export default Card2

export interface BoxProps {
  /* See props documentation at:
   * https://www.smooth-code.com/open-source/smooth-ui/docs/box/#box-2
   */
  /* Display */
  background: number | string
  bg: number | string
  backgroundColor: number | string
  backgroundImage: number | string
  backgroundSize: number | string
  backgroundPosition: number | string
  backgroundRepeat: number | string
  opacity: number | string
  overflow: number | string
  transition: number | string
  border: number | string
  borderTop: number | string
  borderTopColor: number | string
  borderRight: number | string
  borderRightColor: number | string
  borderBottom: number | string
  borderBottomColor: number | string
  borderLeft: number | string
  borderLeftColor: number | string
  borderColor: number | string
  borderWidth: number | string
  borderStyle: number | string
  borderRadius: number | string
  display: number | string
  alignItems: number | string
  alignContent: number | string
  justifyContent: number | string
  justifyItems: number | string
  flexWrap: number | string
  flexBasis: number | string
  flexDirection: number | string
  flex: number | string
  justifySelf: number | string
  alignSelf: number | string
  order: number | string
  gridGap: number | string
  gridColumnGap: number | string
  gridRowGap: number | string
  gridColumn: number | string
  gridRow: number | string
  gridAutoFlow: number | string
  gridAutoColumns: number | string
  gridAutoRows: number | string
  gridTemplateColumns: number | string
  gridTemplateRows: number | string
  gridTemplateAreas: number | string
  gridArea: number | string
  width: number | string
  height: number | string
  maxWidth: number | string
  maxHeight: number | string
  minWidth: number | string
  minHeight: number | string
  size: number | string
  verticalAlign: number | string
  position: number | string
  zIndex: number | string
  top: number | string
  right: number | string
  bottom: number | string
  left: number | string
  boxShadow: number | string
  textShadow: number | string
  margin: number | string
  m: number | string
  marginTop: number | string
  mt: number | string
  marginRight: number | string
  mr: number | string
  marginBottom: number | string
  mb: number | string
  marginLeft: number | string
  ml: number | string
  mx: number | string
  my: number | string
  padding: number | string
  p: number | string
  paddingTop: number | string
  pt: number | string
  paddingRight: number | string
  pr: number | string
  paddingBottom: number | string
  pb: number | string
  paddingLeft: number | string
  pl: number | string
  px: number | string
  py: number | string
  fontFamily: number | string
  fontSize: number | string
  lineHeight: number | string
  fontWeight: number | string
  textAlign: number | string
  letterSpacing: number | string
  color: number | string
  textTransform: number | string
  row: number | string
  col: number | string
}
