import { useCreateFetch } from 'components/tools/useFetch'
import { useEffect, useState } from 'react'

import useStore from './store'

const usePlans = () => {
  const { store, initialLoadSucceeded, setInitialLoadSucceeded, setAll } =
    useStore()
  const { fetch, data, fetching, error, success } = useCreateFetch(`plans`)
  const [plans, setPlans] = useState<any[]>([])

  useEffect(() => {
    if (!success && !initialLoadSucceeded && !fetching && !error) fetch()
  }, [error, fetch, fetching, initialLoadSucceeded, success])

  useEffect(() => {
    if (success && !initialLoadSucceeded) {
      setInitialLoadSucceeded()
      setAll(data)
    }
  }, [data, initialLoadSucceeded, setInitialLoadSucceeded, setAll, success])

  useEffect(() => {
    setPlans(Array.from<any, any>(store, (d) => d[1]))
  }, [store])

  return { plans, fetching, success: setInitialLoadSucceeded, error }
}

export default usePlans
