import request from 'components/tools/request'
import React, { useEffect, useState } from 'react'
import { MdMoreVert } from 'react-icons/md'
import styled from 'styled-components'

import EditAttendee from './EditAttendee'

const Tr = styled.tr`
  height: 2.5em;
  background-color: #fff;
  color: ${(p) => (p.unapproved ? 'orange' : 'default')};
  :nth-child(even) {
    background-color: #f2f2f2;
  }
  border-bottom: solid 1px #e1e1e1;
  cursor: pointer;
`

const Td = styled.td`
  white-space: nowrap;
  padding: 0 10px;
`
const TdShort = styled.td`
  padding: 0px;
  width: 10px;
`
const Tdd = styled.td`
  padding: 0 20px;
`
const WEEK_IN_MS = 6.04e8
const ALLOWED_NUMBER_OF_WEEKS = 3

const Attendee = ({ planId, uid, name = '', firstName = '', lastName = '', createdAt, id, approved, date, dateString, slug, increase, decrease }) => {
  const [status, setStatus] = useState(false)
  const [showEdit, setShowEdit] = useState(false)

  useEffect(() => {
    setStatus(!!date)
    // dateString is important, forces update on every date change
  }, [date, dateString])
  console.log('date, dateString: ', date, dateString)

  const isApproved = () => {
    if (approved === true) return true
    if (createdAt == null) return true
    return new Date(createdAt).getTime() + WEEK_IN_MS * ALLOWED_NUMBER_OF_WEEKS > Date.now()
  }

  const remove = async () => {
    try {
      await request.del(`admin/attendees/${planId}/${dateString}/${id}`)
      setStatus(null)
      decrease()
    } catch (error) {
      console.log(error)
      setStatus(date)
    }
  }

  const add = async () => {
    try {
      await request.post(`admin/attendees/${planId}/${dateString}/${id}`)
      setStatus(!!dateString)
      increase()
    } catch (error) {
      console.log(error)
      setStatus(null)
    }
  }

  const toggle = async () => {
    if (status) {
      await remove()
    } else {
      await add()
    }
  }

  const handleEdit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowEdit(true)
  }

  return (
    <>
      <Tr onClick={toggle} onContextMenu={handleEdit} unapproved={!isApproved()}>
        {uid ? (
          <TdShort onClick={handleEdit}>
            <MdMoreVert />
          </TdShort>
        ) : (
          <Td />
        )}
        <Td>{`${name}${firstName} ${lastName}`}</Td>
        <Tdd>{status ? 'x' : ''}</Tdd>
      </Tr>
      {showEdit && (
        <EditAttendee user={{ name, createdAt, id, approved }} onClose={() => setShowEdit(false)} dataKey={`admin/attendees/${slug}/${dateString}`} />
      )}
    </>
  )
}

export default Attendee
