import Axios from 'axios'
import request from 'components/tools/request'
import userStore from './store'

type User = {
  id: number
  roles: string[]
  authenticated: boolean
  token: string
}

type Resp = {
  id: number
  roles: string[]
  authenticated: true
}

const useUser = (): {
  refresh: () => Promise<void>
  setUser: ({ roles, id, token }: User) => Promise<void>
  user: User
} => {
  const { store: user, setUser: addUser } = userStore()

  const setUser = async ({ roles, id, token }: User) => {
    const user = { authenticated: true, id: id, roles, token }
    localStorage.setItem('user', JSON.stringify(user))
    Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

    addUser(user)
  }

  const refresh = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user') || '')
      if (user) {
        addUser(user)
        Axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`
        const { data } = await request.get<Resp>('user/id')
        const newUser = { ...user, ...data }
        addUser(newUser)
        localStorage.setItem('user', JSON.stringify(newUser))
      }
    } catch (_) {}
  }

  return { user, refresh, setUser }
}

export default useUser
