import React, { useState } from 'react'
import Modal from 'components/basic/Modal/Modal'
import request from 'components/tools/request'
import useSWR from 'swr'
import Text from 'components/styles/Text'
import Input from '../styles/Input'
import Button from 'components/styles/Button'
import { Box } from '@xstyled/styled-components'
import ErrorText from 'components/basic/error'

const EditLinkModal = ({ columnIdx, itemIdx, slug, title, onClose }) => {
  const { data, mutate } = useSWR('info')
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const [newTitle, setNewTitle] = useState(title)
  const [newSlug, setNewSlug] = useState(slug)

  const updateItems = () => {
    const i = [...data.links]
    if (!(newTitle && newSlug)) {
      i[columnIdx].splice(itemIdx, 1)
    } else {
      i[columnIdx][itemIdx] = { title: newTitle, slug: newSlug }
    }
    return i
  }

  const handleUpdate = async () => {
    try {
      setInProgress(true)
      const items = updateItems()
      await request.put('info', { items })
      mutate({ links: items })
      setInProgress(false)
      onClose()
    } catch (error) {
      console.log('error: ', error)
      setInProgress(false)
      setError(error)
    }
  }

  return (
    <Modal dialog isOpen toggle={onClose} persistent={inProgress}>
      <Box margin="2">
        <Text>
          Hvis du opretter et link til en siden der ikke findes, skal du
          efterfølgende ind på den side og oprette den.
        </Text>
        <Text mb="2">
          For at fjerne et link fra listen skal begge felter være tomme.
        </Text>

        <Input
          type="text"
          label="Navn"
          name="title"
          value={newTitle}
          onChange={(e) => setNewTitle(e.currentTarget.value)}
        />
        <Input
          my="2"
          type="text"
          label="Link"
          name="link"
          value={newSlug}
          onChange={(e) =>
            setNewSlug(e.currentTarget.value.replace(/\s+/g, ''))
          }
        />
        <Button right onClick={handleUpdate} loading={inProgress}>
          Gem
        </Button>
        {error && <ErrorText>{error.message}</ErrorText>}
      </Box>
    </Modal>
  )
}

export default EditLinkModal
