import React from 'react'
import styled from 'styled-components'

const itemHeight = 55.6
// const Headline = styled.a`
//   font-size: 1.125rem;
//   margin-right: 0;
//   text-decoration: none;
//   color: white;
//   cursor: pointer;
// `
const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  display: block;
  color: black;
`

const Content = styled.div`
  height: 0;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  background-color: #fff;
  width: 40px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 0;
  z-index: 210;
  transition: height 0.4s, width 0.4s;
  overflow: hidden;

  div,
  a {
    display: block;
    color: black;
    padding: 16px 10px;
    font-size: 1.125rem;
    cursor: pointer;

    &:hover {
      background: #dddddd;
    }
  }

  ${Dropdown}:hover & {
    width: 160px;
    height: ${(p) => itemHeight * p.numberOfItems}px;
  }
`

const MenuDropdown = ({ headline, numberOfItems, children }) => {
  return (
    <Dropdown>
      {headline}
      <Content numberOfItems={numberOfItems}>{children}</Content>
    </Dropdown>
  )
}

export default MenuDropdown
