import RoleAuth from 'components/basic/roleAuth'
import { A, setLinkProps, usePath } from 'hookrouter'
import React, { FC } from 'react'
import roles from 'roles'
import styled from 'styled-components'

import logoPic from './logo.svg'
import Profile from './Profile'

// const BG_COLOR = '#2185d0'
const BG_COLOR = '#2A3746'

const TopBar = styled.div`
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: ${BG_COLOR};
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  @media only screen and (max-width: 767px) {
    height: 54px;
    padding: 0 10px;
  }
`

const MenuBar = styled.div`
  position: fixed;
  z-index: 200;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${BG_COLOR};
  display: flex;
  align-items: ${(p) => (p.iOS ? 'start' : 'center')};
  justify-content: center;
  height: 50px;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  @media only screen and (min-width: 767px) {
    position: static;
    padding: 0;
    align-items: center;
    height: 100%;
    justify-content: flex-start;
  }
`

const MenuItem = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0;
  margin: 4px;

  color: white;
  cursor: pointer;

  font-weight: ${(p) => (p.active ? 'bold' : 'normal')};
  text-transform: capitalize;
  text-decoration: none;
  font-size: 1.1rem;

  @media only screen and (max-width: 320px) {
    font-size: 1rem;
  }

  @media only screen and (min-width: 767px) {
    font-weight: normal;
    font-size: 1.2rem;
    padding: 2px 5px;
    margin: 0 5px;

    :after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: '';
      display: block;
      height: 5px;
      position: absolute;
      background: #fff;
      background: #f5f5f5;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
      border-radius: 3px;
      width: ${(p) => (p.active ? '100%' : 0)};
      left: ${(p) => (p.active ? 0 : '50%')};
    }
    :hover:after {
      width: 100%;
      left: 0;
    }
  }
`

const Logo = styled.img`
  width: 40px;
  padding-right: 20px;
  @media only screen and (max-width: 767px) {
    width: 30px;
  }
`
const LogoLink = styled(A)`
  display: flex;
  align-items: center;
`
const isIos = (): boolean => !!navigator.userAgent.match(/(iPod|iPhone|iPad)/)

const Header: FC = () => {
  const path = usePath()
  const renderMenuItem = (name: string, href: string) => {
    return (
      <MenuItem {...setLinkProps({ href })} active={path.includes(href)}>
        {name}
      </MenuItem>
    )
  }

  return (
    <TopBar>
      <LogoLink href="/">
        <Logo src={logoPic} alt="logo" />
      </LogoLink>
      <MenuBar iOS={isIos()}>
        {renderMenuItem('Nyheder', '/news')}
        {renderMenuItem('Hold', '/teams')}
        {renderMenuItem('Events', '/events')}
        {renderMenuItem('Info', '/info')}
        <RoleAuth
          allowedRole={[
            roles.admin,
            roles.coach,
            roles.roleManager,
            roles.cashier,
            roles.attendeeManager,
          ]}
        >
          {renderMenuItem('Admin', '/admin')}
        </RoleAuth>
      </MenuBar>
      <Profile />
    </TopBar>
  )
}

export default Header
