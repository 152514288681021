import styled, { space } from '@xstyled/styled-components'
import React, { forwardRef } from 'react'

import Box from './Box'

const StyledInput = styled.input`
  width: 100%;
  height: 1.1875em;
  padding: 18px 14px;
  outline: 0;
  border: 1px solid #ddd;
  // border-color: ${(p) => (p.error ? p.theme.colors.danger : '#ddd')};
  // border-radius: 4px;
  background: #fff;
  font-size: inherit;
  border-color: ${(p) => (p.error ? p.theme.colors.danger : '#ddd')};

  border-radius: ${(p) => p.borderRadius || '4px'};

  :focus {
    padding: 17px 13px;
    border-color: primary;
    border-color: ${(p) => (p.error ? p.theme.colors.danger : p.theme.colors.primary)};

    border-width: 2px;
  }
  :disabled {
    background-color: #f7f7f7;
  }
`

const ErrorMessage = styled.div`
  color: ${(p) => (p.error ? p.theme.colors.danger : 'transparent')};
  padding-left: 17px;
  font-size: 10px;
  margin: 0;
  height: ${(p) => (p.error ? '10px' : 0)};
  transition: height 0.2s ease-out, color 0.5s ease-out;
`

const Select = styled.select`
  width: 100%;
  box-sizing: content-box;
  height: 1.1875em;
  padding: 18px 14px;
  outline: 0;
  border: ${(p) => p.border || '1px solid #ddd'};
  border-color: ${(p) => (p.error ? p.theme.colors.danger : '#ddd')};

  border-radius: ${(p) => p.borderRadius || '4px'};
  background: #fff;
  font-size: inherit;

  :focus {
    padding: 17px 13px;
    /* border-color: primary; */
    border-color: ${(p) => (p.error ? p.theme.colors.danger : p.theme.colors.primary)};
    border-width: 2px;
  }
  :disabled {
    background-color: #f7f7f7;
  }
  /* border-color: ${(p) => (p.error ? p.theme.colors.danger : undefined)}; */
`

const Label = styled.label`
  /* color: #999; */
  color: ${(p) => (p.error ? p.theme.colors.danger : '#999')};

  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  padding: ${(p) => (p.active ? '3px' : 0)};
  transform: ${(p) => (p.active ? 'translate(14px, -8px) scale(0.75)' : 'translate(14px, 18px) scale(1)')};
  background-color: ${(p) => (p.active ? 'white' : 'transparent')};
  transform-origin: top left;
  transition: all 0.2s ease-out;
`

const Container = styled.div`
  ${space};
  position: relative;
  display: flex;

  :focus-within {
    ${Label} {
      color: ${(p) => (p.error ? p.theme.colors.danger : p.theme.colors.primary)};
      transform: translate(14px, -8px) scale(0.75);
      background-color: white;
      padding: 3px;
    }
  }
`
export type InputProps = {
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void
  onClick?: React.MouseEventHandler<HTMLInputElement>
  value?: string
  label?: string
  type?: 'number' | 'text' | 'email' | 'password' | 'date' | 'month'
  name?: string
  id?: string
  options?: { label: string; value: string }[]
  select?: boolean
  active?: boolean
  autoComplete?: string
  disabled?: boolean
  placeholder?: string
  error?: any
  onBlur?: any
  onFocus?: any
  onKeyPress?: any
} & {
  m?: number | string
  mx?: number | string
  my?: number | string
  ml?: number | string
  mr?: number | string
  mt?: number | string
  mb?: number | string

  p?: number | string
  px?: number | string
  py?: number | string
  pl?: number | string
  pr?: number | string
  pt?: number | string
  pb?: number | string
} & {
  border?: string
  borderRadius?: string | number
}

// React.forwardRef((props, ref)
// : FC<Props>
const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      active,
      onChange,
      onClick,
      value,
      label,
      type,
      name,
      id,
      options,
      select,

      autoComplete,
      disabled,
      error,
      onBlur,
      onFocus,
      onKeyPress,
      placeholder,
      border,
      borderRadius,
      ...restProps
    },
    ref
  ) => {
    const isActive = value !== '' || active
    return (
      <Box display="flex" flexDirection="column" {...restProps}>
        <Container error={!!error}>
          <Label htmlFor={name} active={isActive} error={!!error}>
            {label}
          </Label>

          {!select ? (
            <StyledInput
              disabled={disabled}
              onKeyPress={onKeyPress}
              placeholder={placeholder}
              onBlur={onBlur}
              onFocus={onFocus}
              name={name}
              id={id}
              type={type}
              value={value}
              onChange={onChange}
              ref={ref}
              error={!!error}
              border={border}
              borderRadius={borderRadius}
            />
          ) : (
            <Select name={name} id={id} onChange={onChange} value={value} disabled={disabled} border={border} borderRadius={borderRadius}>
              {value === '' && <option disabled hidden></option>}
              {options?.map((o) => (
                <option key={o.value} value={o.value}>
                  {o.label}
                </option>
              ))}
            </Select>
          )}
        </Container>
        <ErrorMessage error={!!error}>{error}</ErrorMessage>
      </Box>
    )
  }
)

export default Input
