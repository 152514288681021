const nameFilter = (term: string, firstName: string, lastName: string): boolean => {
  if (term === '') return true
  const terms = term.split(' ').filter(Boolean)
  const firstNameMatch = terms.some((t) => firstName.toLowerCase().includes(t.toLowerCase()))
  const lastNameMatch = terms.some((t) => lastName.toLowerCase().includes(t.toLowerCase()))

  return firstNameMatch || lastNameMatch
}

export default nameFilter
