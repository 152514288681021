import styled, { space, keyframes } from '@xstyled/styled-components'
import { colors, siteWidth } from './styles'

const fadeIn = keyframes`
    0% {
      transform:translateY(10px);
        opacity: 0;
      }
      100% {
        transform:translateY(0);
        opacity: 1;
      }
`

/**
 * @padding padding inside card (1em)
 * @fullWidth
 * @important makes broder primary color
 * @marginTop margin-top (2em)
 * @mt margin-top (2em)
 */
const Card = styled.div`
  border: 1px solid ${(props) => (props.important ? colors.primary : colors.border)};
  border-radius: 8px;
  animation: ${fadeIn} 0.3s linear;
  padding: ${(props) => (props.padding ? '1em' : 0)};
  margin-top: ${(props) => (props.marginTop || props.mt ? '2em' : 0)};
  display: flex;
  flex-direction: column;
  background-color: white;
  color: ${(props) => (props.disabled ? '#b3b3b3' : 'black')};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  overflow: auto;

  @media only screen and (max-width: ${siteWidth}) {
    margin-left: 1em;
    margin-right: 1em;
    width: auto;
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: 500;
  color: ${colors.footer};
  margin: 0;
  padding: ${(p) => (p.padding ? '16px' : 0)};
  ${space};
`

export const Actions = styled.div`
  float: right;
  font-size: 1.2em;
  cursor: pointer;

  svg {
    color: #757575;
  }
`

export const Scroll = styled.div`
  display: flex;
  overflow-x: auto;
`

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;

  @media only screen and (max-width: ${siteWidth}) {
    grid-template-columns: 1fr;
  }
`

export default Card
