import React, { FC, Suspense } from 'react'
import Modal from 'components/basic/Modal'
import { LoadingHigh } from 'components/loading'
import { Title } from 'components/styles/Card'
import SubscribePayment from './SubscribePayment'

type Props = {
  personName: string
  personId: number
  planName: string
  planId: number
  onClose: () => void
}

const SubscribePaymentModal: FC<Props> = ({
  planId,
  planName,
  onClose = () => {},
  personId,
  personName,
}) => {
  return (
    <Modal isOpen={true} toggle={() => onClose()}>
      <Suspense fallback={<LoadingHigh />}>
        <Title>{`Tilmeld ${personName} til ${planName}`}</Title>

        <SubscribePayment
          planId={planId}
          onClose={onClose}
          personId={personId}
        />
      </Suspense>
    </Modal>
  )
}

export default SubscribePaymentModal
