import React, { useState, useEffect } from 'react'
import useSWR from 'swr'
import styled from '@xstyled/styled-components'

import { LoadingHigh } from 'components/loading'
import Text from 'components/styles/Text'

const Table = styled.table`
  border: none;
  border-collapse: collapse;
  margin-top: 4;
`

const TableBody = styled.tbody`
  tr:nth-child(odd) {
    background-color: #fff;
  }
`
const TableHeader = styled.thead`
  th {
    border: none;
    border-bottom: 1px solid;
    border-color: border;
    font-weight: normal;
  }
  tr {
    background-color: transparent;
  }
`

const Td = styled.td`
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: border;
  padding: 2;
  width: 20px;
  text-align: center;
  white-space: nowrap;
`
const TdName = styled(Td)`
  text-align: start;
`

const formatDate = date => {
  return date.toString().replace(/(\d{4})(\d{2})(\d{2})/g, '$3/$2')
}

const calculateTotal = (attendees = []) => {
  return attendees.reduce((total, persons) => {
    persons.attendance.forEach(d => {
      const val = total[d.date] || 0
      total[d.date] = val + 1
    })
    return total
  }, {})
}

const AttendanceList = ({ slug }) => {
  const { data } = useSWR('admin/attendees/' + slug)
  const [total, setTotal] = useState({})

  useEffect(() => {
    if (data && data.attendees) setTotal(calculateTotal(data.attendees))
  }, [data])

  const renderBottomRow = () => {
    return (
      <tr>
        <td></td>
        <Td>I alt</Td>
        {data.dates.map(d => (
          <Td key={d.date}>{total[d.date]}</Td>
        ))}
      </tr>
    )
  }

  const renderRows = () => {
    return data.attendees.map(attendee => {
      return (
        <tr key={attendee.id}>
          <Td>{attendee.attendance.length}</Td>
          <TdName>{`${attendee.firstName} ${attendee.lastName || ''}`}</TdName>

          {data.dates.map(date => {
            if (attendee.attendance.some(a => a.date === date.date)) return <Td key={date.date}>X</Td>
            return <Td key={date.date}>-</Td>
          })}
        </tr>
      )
    })
  }

  if (!data) return <LoadingHigh />
  if (data.attendees.length === 0)
    return (
      <Text mt="30vh" fontSize="4" textAlign="center">
        Der er ingen registreringer for det valgte hold
      </Text>
    )

  return (
    <Table>
      <TableHeader>
        <tr>
          <th></th>
          <th>Navn</th>
          {data.dates.map(d => (
            <th key={d.date}>{formatDate(d.date)}</th>
          ))}
        </tr>
      </TableHeader>
      <TableBody>
        {renderRows()}
        {renderBottomRow()}
      </TableBody>
    </Table>
  )
}

export default AttendanceList
