import useUser from 'components/userManagement/useUser'

const RoleAuth = ({ allowedRole, children }) => {
  const { user } = useUser()
  const isInRole = () => {
    if (!(user.roles && user.roles.length)) return false
    if (Array.isArray(allowedRole)) return allowedRole.filter((element) => user.roles.includes(element)).length >= 1
    if (user.roles.includes(allowedRole)) return true
    return false
  }

  return isInRole() ? children : null
}

export default RoleAuth
